import React, {useEffect, useState} from "react"
import ImgBuilder from "../../res/img/imgBuilder"
import { inject, observer } from "mobx-react"

import "./styles.scss"

const Loader = (props) =>{
    const {
        loadingModel:{loadingModelState}
    } = props
    const [show, setShow] = useState(loadingModelState)

    useEffect(() => {
        setShow(loadingModelState)
    },[loadingModelState])
    return <div className="loaderContainer" style = {{display:`${show ? "inherit" : "none"}`}}>
        <div className="loaderContainerBackground"/>
        <img src = {ImgBuilder.loader}/>
    </div>
}

export default inject(
    "loadingModel"
)(observer(Loader))