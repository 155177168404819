import React, { useEffect, useRef, useState } from "react"
import { Field, ErrorMessage } from "formik"
import "./styles.scss"

const SimpleInput = (props) =>{
    const {
        type, 
        label, 
        name, 
        width, 
        required = false, 
        serverError = "", 
        setErrorsEmpty,
        maxlength = 1000
    } = props
    const [showServerError, setShowServerError] = useState(true)
    const [errorMassageState, setErrorMassageState] = useState(serverError)

    useEffect(() => {
        setErrorMassageState(serverError)
    }, [serverError])

    const handleOnclick = () => {
        if(name === "email" || name === "password" || name === "confirmNewPassword"){
            setErrorsEmpty()
        }
    }

    return <div className = "containerSimpleInput" style = {{width:`${window.innerWidth > width ? width : 300}px`}}>
        <label htmlFor={type}>{label} 
            {required ? <span className = "starSimpleInput">*</span> : ""}
        </label>
        <Field 
            ids = {type}
            name = {name}
            type = {type}
            onClick = {() => handleOnclick()}
            maxlength = {maxlength}
        />
        <div className = "errorSimpleInput" ><ErrorMessage name={name} render={msg => <div>{errorMassageState ? errorMassageState : msg}</div>}/></div>
        {(errorMassageState.length && showServerError) ? <div className = "serverError">{errorMassageState}</div> : null}
    </div>
}


export default SimpleInput