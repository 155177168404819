import React, { useMemo, useEffect } from "react";
import TextArea from "./TextArea";
import { observer, inject } from "mobx-react";
import { useParams } from "react-router-dom";

import "./styles.scss";

const UnAnswered = ({
  questionsModel :{
    saveQuestionAnswer,
    focusedAnswerId,
    positionOfNewAnswerQuestion,
    setFocusedAnswerId
  },
  isMicrosoftEdgeBrowser,
  diagramsDataAfterUpdate,
  unAnsweredQuestions = [],
  priorityValue,
  focusedAreaIndex,
  handleTextareaClick,
  handleTextareaBlur,
  objectiveId
}) => {
  let { id, companyName } = useParams();

  const getCurrentStateParameter = (priorityValue) => {
    if(priorityValue === "normal"){
      return 0
    } else if (priorityValue === "low"){
      return 1
    } else if (priorityValue === "hight"){
      return 2
    }
  }

  const handleSaveQuestionAnswer = (data, text) => {
    const sendData = {
      text,
      question_id: data.id,
      state: getCurrentStateParameter(priorityValue),
      opportunity_id: companyName,
      platform: "web"
    };

    handleTextareaBlur()
    saveQuestionAnswer(sendData, positionOfNewAnswerQuestion);
  };

  const filteredNotAnswerd = useMemo(
    () => {
      return unAnsweredQuestions?.filter((question) => !question.answers[0]?.text).sort((a,b) => a.position - b.position)
    },
    [unAnsweredQuestions]
  );

  return (
    <>
      <div className="cockpitUnswerTitileBlock">
        <p className={isMicrosoftEdgeBrowser ? "cockpitUnswerTitile microsoftEdgeBrowser" :"cockpitUnswerTitile"}>Unanswered</p>
        <div className={isMicrosoftEdgeBrowser ? "answeredCountWrapp microsoftEdgeBrowser":"answeredCountWrapp"}>
          <p className={isMicrosoftEdgeBrowser ? "asweredCount microsoftEdgeBrowser":"asweredCount"}>{filteredNotAnswerd.length}</p>
        </div>
      </div>
      <div className="mockScrool"></div>
      <div className="UnAnsweredContainerScroolBar"
        style={{
          maxHeight: diagramsDataAfterUpdate.length > 1 ? '870px' : undefined
        }}>
        {!!unAnsweredQuestions?.length ? (
          filteredNotAnswerd.map((unAnsweredQuestion, index) => {
            return (
              !unAnsweredQuestion.answers[0].text && (
                <div
                  className="UnAnsweredContainer"
                  key={unAnsweredQuestion.id}
                  style={{
                    border: `${
                      focusedAnswerId === unAnsweredQuestion.id
                        ? "1px solid #6DB6FE"
                        : "none"
                    }`,
                    boxShadow:
                    focusedAnswerId === unAnsweredQuestion.id
                        ? " 0px 2px 10px rgba(15, 86, 224, 0.5)"
                        : "",
                    borderRight: `${
                      focusedAnswerId === unAnsweredQuestion.id
                        ? "1px solid #6DB6FE"
                        : ""
                    }`,
                    position: "relative"
                  }}
                >
                  <div
                      style={{
                        color: `${
                          focusedAnswerId === unAnsweredQuestion.id
                            ? "#0F56E0"
                            : "#1c1c1c"
                        }`,
                      }}
                      className="kritikalPolosUnAnswerd"
                    />
                  <div className={`unAnsweredContainerWithoutLeftLine ${(focusedAnswerId && focusedAnswerId === unAnsweredQuestion.id) ? "unAnsweredContainerWithoutLeftLineAcive" : ""}`}>
                    <div className="blueCircleSpan">
                      <span className={isMicrosoftEdgeBrowser?"unAnsweredQuestionQuestionText microsoftEdgeBrowser":"unAnsweredQuestionQuestionText"}>
                        {unAnsweredQuestion.questionText}
                      </span>
                      {unAnsweredQuestion.critical && (
                        <div className="blueCircle"></div>
                      )}
                    </div>

                    <TextArea
                      isMicrosoftEdgeBrowser={isMicrosoftEdgeBrowser}
                      unAnsweredQuestion={unAnsweredQuestion}
                      index={index}
                      handleSaveQuestionAnswer={handleSaveQuestionAnswer}
                      onClick={() => handleTextareaClick(unAnsweredQuestion.id, objectiveId)}
                      type="text"
                      placeholder="Click to answer"
                      maxLength={500}
                      rows="3"
                      name={index}
                      data-name="name"
                      focusedAreaIndex={focusedAreaIndex}
                      onBlur={handleTextareaBlur}
                    />
                  </div>
                </div>
              )
            );
          })
        ) : (
          <p className="notNAswerNotif">
            There are no questions in this objective.<br/> Please, use the
            application to add the question.
          </p>
        )}
      </div>
    </>
  );
};

export default inject(
  "questionsModel",
)(observer(UnAnswered));
