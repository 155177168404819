import React, { useEffect, useState } from "react";
import ImgBuilder from "../../res/img/imgBuilder";

import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { homeUrl, login } from "../../res/consts/router_urls_const";
import Breadcrumbs from "../Breadcrumbs";
import { inject, observer } from "mobx-react";

import "../../res/consts/global_styles.scss";

import "./styles.scss";

const Header = (props) => {
  const {
    scaleStyles,
    opportunitiesModel: { currentOppotunity },
    authModel: {
      logout,
      currenManager,
      auth_token,
      setAuthToken,
      getCurrentManeger,
    },
  } = props;

  const [breadcrumbsTitle, setBreadcrumbsTitle] = useState("");
  const [userName, setUserName] = useState("");
  const [firstLetters, setFirstLetters] = useState("");

  const navigate = useNavigate();

  let { id, companyName } = useParams();

  const handlebreadcrumbFilter = (url) => {
    navigate(url);
  };

  useEffect(() => {
    if (currentOppotunity?.company) {
      let title = currenManager?.title
        ? currenManager?.title
        : currenManager.first_name + ", " + currenManager.last_name;

      setBreadcrumbsTitle(title);
    }
  }, [currentOppotunity]);

  useEffect(() => {
    if (currenManager?.title) {
      setBreadcrumbsTitle(currenManager.title);
    }

    if (!auth_token.length && localStorage.getItem("auth_token_cockpit")) {
      setAuthToken(localStorage.getItem("auth_token_cockpit"));
      getCurrentManeger();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(currenManager).includes("first_name")) {
      let userNameDraft =
        currenManager.first_name + " " + currenManager.last_name;
      let firstLettersDraft = userNameDraft.split(" ").map((word) => word[0]);
      setUserName(userNameDraft);
      setFirstLetters(firstLettersDraft);
    }
  }, [currenManager]);

  return (
    <header className="mainHeader">
      <div className="container">
        <div
          className={`row align-items-center contentContainerMainHeader ${
            companyName ? "withBreadcrumbsMainHeader" : ""
          }`}
          styles = {scaleStyles}
        >
          <div className="headerLogoWrapperLeft col-auto me-auto">
            <img src={ImgBuilder.mainlogo} className="headerLogo"/>
          </div>
          <div className="col-auto initialsMainHeader">
            <div className="row align-items-center gx-3">
            {currenManager?.image?.url ? (                
                <div className="col">
                    <img src={currenManager?.image?.url} className="headerInitialsAvatar" />
                </div>
              ) : (
                <div className="col">
                  <div className="headerInitials">{firstLetters}</div>
                </div>
              )}
              <div className="headerUserName col">
                <div className="headerUserName">{userName}</div>
              </div>
              <div className="headerLogout col">
                <div className="headerLogout" onClick={() => logout()}>
                  Logout
                </div>
              </div>
            </div>
          </div>
          {/*companyName && window.innerWidth < 768 && (
            <div className="col-auto me-auto containerBreadcrumbsMainHeader">
              <Breadcrumbs
                chains={["Dashboard", breadcrumbsTitle]}
                urls={homeUrl}
              />
            </div>
          )*/}
        </div>
      </div>
    </header>
  );
};

export default inject("opportunitiesModel", "authModel")(observer(Header));
