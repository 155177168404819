import mainlogo from "./mainlogo.svg";
import mainlogoTM from "./mainlogoTM.svg";
import personIcon from "./personIcon.svg";
import filterArrowDown from "./filterArrowDown.svg";
import filterArrowDownBlue from "./filterArrowDownBlue.svg";
import arrowLeft from "./arrowLeft.svg";
import arrowRight from "./arrowRight.svg";
import forcastFilter from "./forcastFilter.svg";
import emptyPageImage from "./emptyPageImage.svg";
import loginImage from "./loginImage.svg";
import backgroundLogo from "./backgroundLogo.svg";
import refreshIcon from "./refreshIcon.svg";
import loader from "./loader.svg";
import trashIcon from "./trashIcon.svg";
import expandIcon from "./expandIcon.svg";
import dragMenuIcon from "./dragMenuIcon.svg";
import makeLessIcon from "./makeLessIcon.svg";
import closeMessageIcon from "./closeMessagesIcon.svg";
import closed from "./closed.svg";
import send from "./send.svg";
import brightReloadIcon from "./brightReloadIcon.svg";
import selectPic from "./selectPic.svg";
import whiteTriangularIcon from "./whiteTriangularIcon.svg";
import shapeGreen from "./shapeGreen.svg";
import shapePink from "./shapePink.svg";
import shapeRed from "./shapeRed.svg";
import close from "./close.svg";
import blueCrossIcon from "./blueCrossIcon.svg"

const ImgBuilder = {
  mainlogo,
  personIcon,
  filterArrowDown,
  arrowRight,
  arrowLeft,
  forcastFilter,
  emptyPageImage,
  loginImage,
  mainlogoTM,
  backgroundLogo,
  filterArrowDownBlue,
  refreshIcon,
  loader,
  trashIcon,
  expandIcon,
  dragMenuIcon,
  makeLessIcon,
  closeMessageIcon,
  closed,
  send,
  brightReloadIcon,
  selectPic,
  whiteTriangularIcon,
  shapeGreen,
  shapePink,
  shapeRed,
  close,
  blueCrossIcon,
};

export default ImgBuilder;
