import React, {useEffect, useState} from "react"
import DropdownInput from "../../components/Filters/DropdownInput"
import ObjectiveDiagrams from "../../components/ObjectiveDiagrams"
import DealDashboardMainTable from "../../components/Tables/DealDashboardMainTable"
import ImgBuilder from "../../res/img/imgBuilder"
import EmptyPage from "../../components/EmptyPage"
import { inject, observer } from "mobx-react"
import Loader from "../../components/Loader"

import "./styles.scss"

const UserInfoPage = (props) =>{
    const {
        opportunitiesModel:{opportunitiesList, currentOppotunity, getOpportunitiesList},
        authModel:{auth_token, setAuthToken, getCurrentManeger, currentManeger},
        objectivesModel:{getObjectivesList},
        loadingModel:{loadingModelState, setLoadingModelState},
    } = props
    const [currentCompany, setCurrentCompany] = useState({})

    let modifyLocalStorage = (action) =>{

        //opp id
        let alreadyDeletedMain = false
        let openedOpportunitiesArr = localStorage.getItem("openedOpportunityCockpit") ?
            localStorage.getItem("openedOpportunityCockpit").split(",")
            .filter(opp => {
                if(!alreadyDeletedMain && ("main" === opp)){
                    alreadyDeletedMain = true
                    return false
                }else{
                    return true
                }
            }) :
            []
        let openedOpportunitiesStr

        if(action === "close"){
            if(openedOpportunitiesArr.length){
                openedOpportunitiesStr = openedOpportunitiesArr.join(",")
            }else{
                localStorage.clear()
            }
        }else{
            openedOpportunitiesArr.push("main");
            openedOpportunitiesStr = openedOpportunitiesArr.join(",")
        }
        localStorage.setItem("openedOpportunityCockpit", JSON.parse(JSON.stringify(openedOpportunitiesStr)))
    }



    useEffect(() => {
        let token = localStorage.getItem("auth_token_cockpit")
        setAuthToken(token)
        modifyLocalStorage("open")
    }, [])

    window.onbeforeunload = () => {modifyLocalStorage("close")}


    const handleRefresh = () =>{
        setLoadingModelState(true)
        getOpportunitiesList(currentManeger.id)
        //getObjectivesList(filteredUser.id)
        setTimeout(() => {setLoadingModelState(false)}, 2000)
    }

    return <div className = "containerUserInfoPage">
        {/*<div className = "p-3 topFilter">
            <div className="refreshButton" onClick={() => handleRefresh()}>
                <img src = {ImgBuilder.refreshIcon}/>
                <span>Refresh</span>
            </div>
</div>*/}
        {opportunitiesList?.length ? <>
            <div className="hint">
                Click on any opportunity to select. Click to any discovery objective to drill-in.
            </div>
            <ObjectiveDiagrams data = {{}} currentCompany = {""}/>
            <div className = "mt-5" style = {{overflowX:"auto"}}>
                <DealDashboardMainTable setCurrentCompany = {setCurrentCompany}/>
            </div>
        </> :
            <EmptyPage/>
        }
    </div>
}

export default inject(
    "opportunitiesModel",
    "authModel",
    "objectivesModel",
    "loadingModel",
)(observer(UserInfoPage))
