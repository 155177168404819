import React, { useState, useRef, useEffect } from "react";
import ImgBuilder from "../../../../res/img/imgBuilder";
import { observer, inject } from "mobx-react";
import ".././styles.scss";

const TextArea = (props) => {
  const {
    questionsModel :{
      focusedAnswerId,
      setFocusedAnswerId
    },
    isMicrosoftEdgeBrowser,
    onClick,
    focusedAreaIndex,
    index,
    handleSaveQuestionAnswer,
    unAnsweredQuestion,
    onBlur,
  } = props
  const [answerText, setAnswerText] = useState("");
  const saveBtnRef = useRef();
  const clearBtnRef = useRef();
  const textareaRef = useRef(null)

  const handleChange = ({ target: { value } }) => {
    if(value && value[value.length - 1].charCodeAt() < 400 || !value[value.length - 1]){
      setAnswerText(value)
    } 
  };

  const handleDeleteInputText = (event) => {
    
    setAnswerText("");
    setFocusedAnswerId(null)
    textareaRef?.current.blur()
  };

  /**
   * On blure handler.
   * @param { object } event.
   * DOM OnBlure event object.
   */
  const handleBlur = (event) => {
    onBlur(event)
  };

  useEffect(() => {
    !focusedAnswerId && setAnswerText("")
  },[focusedAnswerId])

  return (
    <div className={isMicrosoftEdgeBrowser ? "textAreaWrapp microsoftEdgeBrowser":"textAreaWrapp"}>
      <textarea
        onChange={handleChange}
        value={answerText}
        style={{
          borderRadius: "2px",
          border: `${
            focusedAreaIndex === unAnsweredQuestion.id
              ? "1px solid #6DB6FE"
              : "none"
          }`,
          height: `${
              focusedAreaIndex === unAnsweredQuestion.id
                  ? "100%"
                  : "30px"
          }`,
        }}
        onFocus={onClick}
        type="text"
        placeholder="Click to answer"
        maxLength={500}
        name={index}
        data-name="name"
        onBlur={handleBlur}
        ref = {textareaRef}
      />
      {focusedAreaIndex === unAnsweredQuestion.id && answerText.length > 0 && (
        <div tabIndex={0} data-property="true" className="messageIconWrap">
          <div tabIndex={0} data-property="true" className="imageWrapper">
            <button
              data-property="true"
              ref={saveBtnRef}
              onClick={() => {
                handleSaveQuestionAnswer(unAnsweredQuestion, answerText);
              }}
            >
              <img src={ImgBuilder.send} alt="svg" />
            </button>
          </div>

          <div tabIndex={0} data-property="true" className="imageWrapper">
            <button ref={clearBtnRef} onClick={() => handleDeleteInputText("???")}>
              <img
                tabIndex={0}
                data-property="true"
                src={ImgBuilder.close}
                alt="svg"
              />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default inject(
  "questionsModel",
)(observer(TextArea));
