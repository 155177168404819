import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import ImgBuilder from "../../../res/img/imgBuilder";

import "./styles.scss";

const radioInputValues = [
  { name: "High", id: 0 },
  { name: "Low", id: 1 },
  { name: "Normal", id: 2 },
];

const ButtonsPanel = (props) => {
  const {
    questionsModel:{
      lastPriority,
      setLastPriority
    },
    isMicrosoftEdgeBrowser,
    handleChackRadio, 
    value, 
    btnRef
  } = props
  const [currentItem, setCurrentItem] = useState(undefined)

  
  useEffect(() => {
    value && setCurrentItem(radioInputValues.find(color => color.name === value))
  }, [value])

  const getIcon = (index) =>{
    switch(index){
      case 0:
        return ImgBuilder.shapeRed
      case 1:
        return ImgBuilder.shapePink
      case 2:
        return ImgBuilder.shapeGreen
    }
  }

  const getColor = (index, background = false) => {
    if(background && currentItem && currentItem.id === index){
      switch (index) {
        case 0:
          return "#FF0650";
        case 1:
          return "#F6949F";
        case 2:
          return "#75DB90";
      }
    }else if(background){
      return "unset"
    }else if(currentItem && currentItem.id === index){
      return "white"
    }else{
      switch (index) {
        case 0:
          return "#FF0650";
        case 1:
          return "#F6949F";
        case 2:
          return "#75DB90";
      }
    }
  };
  
  return (
    <div className="buttonsPanel" tabIndex="0">
      <div className="buttonsPanelWrapp">
        {radioInputValues.map((radioInputValue, index) => {
          return (
            <button 
              data-property="true"
              key={radioInputValue.id} 
              className={isMicrosoftEdgeBrowser ? "answerPriorityButton microsoftEdgeBrowser": "answerPriorityButton"}
              style = {{
                color:`${getColor(index)}`,
                background: `${getColor(index, true)}`
              }}
              onClick = {(e) => {handleChackRadio(radioInputValue.name); e.stopPropagation()}}
              ref={btnRef}
            >
              <div className="answerCircle" style = {{color:`${getColor(index)}`}}>
                {(currentItem && (currentItem.id === index)) ? <img src = {getIcon(index)}/> :null}
              </div>
              <span style = {{color:`${getColor(index)}`}}>{radioInputValue.name}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default inject(
  "questionsModel",
)(observer(ButtonsPanel));
