import "./styles.scss";
import React, { useEffect, useRef, useState } from "react";
import ImgBuilder from "../../../../res/img/imgBuilder";
import { observer, inject } from "mobx-react";
import "./styles.scss";

const TextAreaAnswered = (props) => {
  const {
    questionsModel :{
      focusedAnswerId,
      setFocusedAnswerId
    },
    isMicrosoftEdgeBrowser,
    onClick,
    onBlur,
    focusedAreaIndex,
    index,
    answeredQuestion,
    handleSaveQuestionAnswerAfterUbdate,
  } = props

  const saveBtnRef = useRef();
  const clearBtnRef = useRef();
  const textInputRef = useRef(null)
  const [answerText, setAnswerText] = useState("");

  const handleChange = ({ target: { value } }) => {
    if(value && value[value.length - 1].charCodeAt() < 400 || !value[value.length - 1]){
      setAnswerText(value)
    } 
  };

  const handleDeleteInputText = (event) => {
    setAnswerText("");
    setFocusedAnswerId(null)
  };

  useEffect(() => {
    if(focusedAnswerId && (focusedAnswerId === answeredQuestion.id) && textInputRef?.current){
      textInputRef?.current.focus()
    }
  }, [focusedAnswerId])

  /**
   * On blure handler.
   * @param { object } event.
   * DOM OnBlure event object.
   */
  const handleBlur = (event) => {
    onBlur(event);
  };

  return (
    <div className={isMicrosoftEdgeBrowser ? "textAreaWrappAnswer microsoftEdgeBrowser": "textAreaWrappAnswer"}>
      <textarea
        onChange={handleChange}
        onBlur={handleBlur}
        value={answerText}
        style={{
          borderRadius: "2px",
          border: `${
            focusedAreaIndex === answeredQuestion.id
              ? "1px solid #6DB6FE"
              : "none"
          }`,
        }}
        onFocus={onClick}
        type="text"
        maxLength={500}
        rows="3"
        name={index}
        data-name="name"
        ref = {textInputRef}
      />

      {focusedAreaIndex === answeredQuestion.id && answerText.length > 0 && (
        <div tabIndex={0} data-property="true" className="messageIconWrap">
          <div tabIndex={0} data-property="true" className="imageWrapper">
            <button
            data-property="true"
              ref={saveBtnRef}
              onClick={() => {
                handleSaveQuestionAnswerAfterUbdate(
                  answeredQuestion,
                  answerText
                );
              }}
            >
              <img src={ImgBuilder.send} alt="svg" />
            </button>
          </div>
          <div tabIndex={0} data-property="true" className="imageWrapper">
            <button data-property="true" ref={clearBtnRef} onClick={handleDeleteInputText}>
              <img tabIndex={0} data-property="true" src={ImgBuilder.close} alt="svg" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default inject(
  "questionsModel",
)(observer(TextAreaAnswered));
