import { makeAutoObservable, action } from "mobx";
import axios from "axios";
import { authModel } from "./auth";
import { opportunitiesModel } from "./opportunities";
import { questionsModel } from  "./questions";
import { forecastFilterCategories } from "../res/consts/filtersVariants";
import { serverAPImodel } from "./serverAPI";
import { toJS } from "mobx";

const getColor = (priority) => {
  switch (priority) {
    case "low":
      return "#F6949F";
    case "hight":
      return "#FF0650";
    case "high":
      return "#FF0650";
    default:
      return "#75DB90";
  }
};

const getFixedPriority = (priority) => {
  if (priority === "hight") {
    // as BE sends property with typo
    return "high";
  }
  return priority;
};

export class ObjectivesModel {
  constructor() {
    this.objectivesList = [];
    this.filteredObjectivesList = [];
    this.currentObjective = {};
    this.diagramsData = [];
    this.objectivesWithCriticalQuestions = [];
    this.monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    this.diagramsDataAfterUpdate = [];
    makeAutoObservable(this);
  }

  /**
   * Save question answer.
   * @param { object } payload.
   * contain answer info.
   */

  setObjectivesWithCriticalQuestions = (objectives) => {
    let result = objectives.filter(
      (objective) => objective.topics[0]?.questions?.length
    );
    this.objectivesWithCriticalQuestions = result.length
      ? result.map((obj) => obj.id)
      : [];
  };

  setObjectivesList = (objectivesList) => {
    this.objectivesList = objectivesList;
  };

  setFilteredObjectivesList = (filteredObjectivesList) => {
    this.filteredObjectivesList = filteredObjectivesList;
  };

  setCurrentObjective = (currentObjective) => {
    this.currentObjective = currentObjective;
  };

  setDiagramsData = (diagramsData) => {
    this.diagramsData = diagramsData;
  };

  addDiagramInDiagramsDataAfterUpdate = (diagramId) => {
    if (this.diagramsDataAfterUpdate.some((item) => item.id === diagramId)) {
      return;
    }
    const newCurrentDiagramData = this.diagramsData.find(
      (item) => item.id === diagramId
    );
    this.diagramsDataAfterUpdate = [
      ...this.diagramsDataAfterUpdate,
      newCurrentDiagramData,
    ];
  };

  deleteDiagramInDiagramsDataAfterUpdate = (diagramId) => {
    const newCurrentDiagramData = toJS(this.diagramsDataAfterUpdate).filter(
      (item) => item.id !== diagramId
    );
    this.diagramsDataAfterUpdate = [...newCurrentDiagramData];
  };

  getObjectivesList = () => {
    const setObjectivesList = this.setObjectivesList;
    const setObjectivesWithCriticalQuestions =
      this.setObjectivesWithCriticalQuestions;
    const headers = {
      headers: {
        accept: "application/json, text/plain, */*",
        "auth-token": authModel.auth_token,
      },
      params: {
        user_id: authModel.currenManager.id,
      },
    };
    axios
      .get(`${serverAPImodel.baseAPI}objectives`, headers)
      .then(function (response) {
        setObjectivesList(response.data.objectives);
        setObjectivesWithCriticalQuestions(response.data.objectives);
        opportunitiesModel.getModelList("companies");
        opportunitiesModel.getModelList("cases");
        opportunitiesModel.getModelList("products");
        opportunitiesModel.getOpportunitiesList();
        forecastFilterCategories.map((category) =>
          opportunitiesModel.getOpportunitiesList(category)
        );
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };
  answerColor = (priorityState) => {
    let result = "default";
    if (priorityState === 1) {
      result = "low";
    } else if (priorityState === 2) {
      result = "high";
    }
    return result;
  };
  formatingTime = (dateTime) => {
    const date = new Date();
    const offset = date.getTimezoneOffset() / 60;
    let result = "";
    let dateList = dateTime.split("T")[0].split("-");
    let timeList = dateTime.split("T")[1].split(":");
    result =
      result +
      (parseInt(timeList[0] - offset - 12) > 0
        ? timeList[0] - offset - 12
        : timeList[0] - offset) +
      ":";
    result = result + dateTime.split("T")[1].split(":")[1];
    result = result + (parseInt(timeList[0] - offset - 12) > 0 ? "PM" : "AM");
    result =
      this.monthNames[dateList[1] - 1] +
      " " +
      dateList[2] +
      "," +
      dateList[0] +
      " " +
      result;
    return result;
  };

  formatingName = () => {
    let result = " ";
    let filteredUserLS = authModel.currenManager;
    filteredUserLS.title = filteredUserLS?.title
      ? filteredUserLS?.title
      : filteredUserLS.first_name + ", " + filteredUserLS.last_name;
    let filteredUserTitleList =
      filteredUserLS?.title && filteredUserLS.title.split(", ");
    result = result + filteredUserTitleList[1] + " " + filteredUserTitleList[0];
    return result;
  };

  filterAnswersByOpportunity = (answers, opportunity) => {
    if (answers.length) {
      let filteredAnswers = answers.filter((answer) => {
        return answer.opportunity_id === opportunity.company.id;
      });
      if (filteredAnswers.length) {
        return filteredAnswers;
      }
      return [];
    }
    return answers;
  };

  checkIfUpdatedQuestionChanged = (objectiveId) => {
    const objectivesList = this.objectivesList;
    const opportunitiesListId = opportunitiesModel.opportunitiesList.map(
      (opp) => opp.id
    );
    const headers = {
      headers: {
        accept: "application/json, text/plain, */*",
        "auth-token": authModel.auth_token,
      },
      params: {
        user_id: authModel.currenManager.id,
      },
    };
    const interestFields = ["opportunity_id", "position", "removed", "title"];
    axios
      .get(`${serverAPImodel.baseAPI}objectives/${objectiveId}`, headers)
      .then(function (response) {
        if (
          response?.data &&
          opportunitiesListId.includes(response?.data?.opportunity_id)
        ) {
          let objectiveUpdatedPreviousState = objectivesList.filter(
            (objective) => objective.id === response.data.id
          ).length
            ? objectivesList.filter(
                (objective) => objective.id === response.data.id
              )[0]
            : null;

          let objectiveUpdatedCurrentState = response.data;

          if (
            !objectiveUpdatedPreviousState &&
            !objectiveUpdatedCurrentState.removed
          ) {
            authModel.setNeedToRefresh(true);
            return;
          }
          if (
            interestFields.some(
              (field) =>
                objectiveUpdatedPreviousState[field] !==
                objectiveUpdatedCurrentState[field]
            )
          ) {
            authModel.setNeedToRefresh(true);
            return;
          }
        }
      });
  };

  checkIfOpenOpportunityDataChanged = (model, id) => {
    const opportunitiesListId = opportunitiesModel.opportunitiesList.map(
      (opp) => opp.id
    );
    const headers = {
      headers: {
        accept: "application/json, text/plain, */*",
        "auth-token": authModel.auth_token,
      },
    };
    let requestedModel = model == "Question" ? "questions" : "answers";
    axios
      .get(`${serverAPImodel.baseAPI}${requestedModel}/${id}`, headers)
      .then(function (response) {

        if(model === "Answer" && response.data.platform === "web") {
          return;
        }

        if(model === "Question" && response.data.answers[0].platform === "web") {
          return;
        }

        if (response?.data && model === "Question") {
          authModel.setNeedToRefresh(true);
        } else if (
          response?.data &&
          opportunitiesListId.includes(response?.data?.opportunity_id) &&
            response.data.question_id !== questionsModel.savedQuestionId
        ) {
          authModel.setNeedToRefresh(true);
        }
      })
      .catch(function (error) {
          authModel.setNeedToRefresh(true);
      });
  };

  buildDiagramsData = (currentItem) => {
    if (
      currentItem?.company?.id ===
      opportunitiesModel.currentOppotunity?.company?.id
    ) {
      let objectivesList = this.objectivesList;
      let setFilteredObjectivesList = this.setFilteredObjectivesList;
      let setDiagramsData = this.setDiagramsData;
      let answerColor = this.answerColor;
      let formatingTime = this.formatingTime;
      let formatingName = this.formatingName;
      let filterAnswersByOpportunity = this.filterAnswersByOpportunity;
      let objectivesIdListDraft =
        currentItem.company.objectives_id_list.split(";");
      let objectivesIdList = [...new Set(objectivesIdListDraft)];
      const preDraftFilteredObjectives = objectivesIdList
        .map((objectiveId) =>
          objectivesList.filter((objective) => objective.id === objectiveId)
            .length
            ? objectivesList.filter(
                (objective) => objective.id === objectiveId
              )[0]
            : null
        )
        .filter((objective) => !!objective);
      const draftFilteredObjectives = preDraftFilteredObjectives.filter(
        (objective) =>
          currentItem.company?.objectives_id_list.includes(objective.id) &&
          !(
            currentItem.company?.removed_objectives_id_list &&
            currentItem.company.removed_objectives_id_list.includes(
              objective.id
            )
          )
      );

      setFilteredObjectivesList(draftFilteredObjectives);
      let diagramsDataDraft = draftFilteredObjectives.map((objective) => {
        let questionData = objective.topics[0].questions.map((question) => {
          let lastAnswer = {};

          let filteredAnswersByOpportunity = filterAnswersByOpportunity(
            question.answers,
            currentItem,
          );

          if (filteredAnswersByOpportunity.length) {
            lastAnswer = {
              answerId: 0,
              answertime: new Date(filteredAnswersByOpportunity[0].updated_at),
            };
            let answerIteration = filteredAnswersByOpportunity.map(
              (answer, id) =>
                id !== 0 &&
                lastAnswer.answertime < new Date(answer.updated_at) &&
                (lastAnswer = {
                  answerId: id,
                  answertime: new Date(answer.updated_at),
                })
            );
          }
          return {
            questionText: question.title,
            id: question.id,
            color: getColor(question?.answers[0]?.priority),
            critical: question?.critical,
            position: question.position,
            answers: [
              filteredAnswersByOpportunity.length
                ? {
                    text:
                      filteredAnswersByOpportunity[lastAnswer.answerId].text +
                      (filteredAnswersByOpportunity[lastAnswer.answerId]
                        .updated_at ===
                      filteredAnswersByOpportunity[lastAnswer.answerId]
                        .created_at
                        ? ""
                        : " (edited)"),
                    priority: getFixedPriority(
                      filteredAnswersByOpportunity[lastAnswer.answerId].priority
                    ),
                    state: filteredAnswersByOpportunity[lastAnswer.answerId].state,
                    color: answerColor(
                      filteredAnswersByOpportunity[lastAnswer.answerId].priority
                    ),
                    name: formatingName(),
                    date: formatingTime(
                      filteredAnswersByOpportunity[lastAnswer.answerId]
                        .updated_at
                    ),
                    dateWithoutFormating: new Date(
                      filteredAnswersByOpportunity[
                        lastAnswer.answerId
                      ].updated_at
                    ),
                  }
                : {},
            ],
          };
        });

        return {
          id: objective.id,
          title: objective.title,
          totalQuestionCount: objective.topics[0].questions.reduce(
            (total, question) => {
              return total + 1;
            },
            0
          ),
          answeredQuestionCount: objective.topics[0].questions.reduce(
            (total, question) => {
              return (
                total +
                (filterAnswersByOpportunity(question.answers, currentItem)
                  .length
                  ? 1
                  : 0)
              );
            },
            0
          ),
          questionInfo: questionData,
        };
      });

      setDiagramsData(diagramsDataDraft);
      if (this.diagramsDataAfterUpdate?.length && diagramsDataDraft.length) {
        const newCurrentDiagramData = this.diagramsDataAfterUpdate.reduce(
          (acc, item) => {
            const current = diagramsDataDraft.find(
              (currentDiagram) => currentDiagram.id === item.id
            );
            if (current) {
              return [...acc, current];
            }
            return acc;
          },
          []
        );

        if (newCurrentDiagramData.length) {
          this.diagramsDataAfterUpdate = [...newCurrentDiagramData];
        }
      }
    }
  };
}

export const objectivesModel = new ObjectivesModel();
