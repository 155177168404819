import React, { useEffect, useState, useLayoutEffect } from "react";
import DropdownInput from "../../Filters/DropdownInput";

import ImgBuilder from "../../../res/img/imgBuilder";
import { observer, inject } from "mobx-react";
import { amountFilterCategories } from "../../../res/consts/filtersVariants";
import { closeDateFilterCategories } from "../../../res/consts/filtersVariants";
import { forecastFilterCategories } from "../../../res/consts/filtersVariants";
import { opportunityPage } from "../../../res/consts/router_urls_const";
import NeedToRefreshAlert from "../../NeedToRefreshAlert";

import "./styles.scss";
import { useRef } from "react";
import { useNavigate, Link } from "react-router-dom";

const DealDashboardMainTable = (props) => {
  const {
    opportunitiesModel: {
      getOpportunitiesList,
      opportunitiesList,
      setCurrentOppotunity,
      opportunitiesListCommited,
      opportunitiesListExpected,
      opportunitiesListUpside,
      opportunitiesListPipeline,
      currentOppotunity,
      companiesList,
      casesList,
      productList,
    },
    objectivesModel: { setDiagramsData, diagramsData },
    authModel: { currenManager, tableSizeParams, setTableSizeParams },
    serverAPImodel: { baseAPI },
    index,
  } = props;

  const [editedOrderList, setEditedOrderList] = useState(opportunitiesList);
  const [dateTypeSort, setDateTypeSort] = useState("New first");
  const [amountTypeSort, setAmountTypeSort] = useState("Not selected");
  const [forcastType, setForcastType] = useState(
    localStorage.getItem("forcastTypeCockpit")
      ? localStorage.getItem("forcastTypeCockpit")
      : "All"
  );
  const [totalCommited, setTotalCommited] = useState(
    editedOrderList.reduce((total, company) => {
      return total + company.amount;
    }, 0)
  );

  const [setterCurrentOppAlreadyUsed, updateSetterCurrentOppAlreadyUsed] =
    useState(false);
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const handleResize = () => {
    if (tableRef) {
      setTableSizeParams({ width: tableRef.current.clientWidth, height: tableRef.current.clientHeight });
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("currentObjectiveIdCockpit")) {
      localStorage.removeItem("currentObjectiveIdCockpit");
    }
  }, []);

  useEffect(() => {
    if (editedOrderList.length) {
      let storagedCurrentUser = localStorage.getItem(
        "currentOppotunityIdCockpit"
      )
        ? localStorage.getItem("currentOppotunityIdCockpit").includes('"')
          ? localStorage
              .getItem("currentOppotunityIdCockpit")
              .substring(1)
              .slice(0, -1)
          : localStorage.getItem("currentOppotunityIdCockpit")
        : 0;
      let filteredData = editedOrderList.filter(
        (opportunity) => opportunity.id === storagedCurrentUser
      );
      let result =
        storagedCurrentUser && filteredData.length
          ? filteredData[0]
          : editedOrderList[0];
      setTotalCommited(
        editedOrderList.reduce((total, company) => {
          return total + company.amount;
        }, 0)
      );
      !setterCurrentOppAlreadyUsed && setCurrentOppotunity({ company: result });
    } else {
      setDiagramsData([]);
    }
  }, [editedOrderList, opportunitiesList]);

  useEffect(() => {
    if (tableRef) {
      let tableWidth = tableRef.current.clientWidth;
      let tableHeight = tableRef.current.clientHeight;
      setTableSizeParams({ width: tableWidth, height: tableHeight });
    }
  }, [tableRef]);

  const forecastFilterCategoriesFormated = forecastFilterCategories.map(
    (category) => {
      switch (category) {
        case "Committed":
          return { title: category, count: opportunitiesListCommited.length };
        case "Expected":
          return { title: category, count: opportunitiesListExpected.length };
        case "Upside":
          return { title: category, count: opportunitiesListUpside.length };
        case "Pipeline":
          return { title: category, count: opportunitiesListPipeline.length };
      }
    }
  );
  forecastFilterCategoriesFormated.push({
    title: "Uncategorized",
    count:
      opportunitiesList.length -
      opportunitiesListCommited.length -
      opportunitiesListExpected.length -
      opportunitiesListUpside.length -
      opportunitiesListPipeline.length,
  });
  forecastFilterCategoriesFormated.push({
    title: "All",
    count: opportunitiesList.length,
  });

  //uncategorized category calculation
  const categorizedIds = [];
  opportunitiesListCommited.length &&
    opportunitiesListCommited.filter((opp) => categorizedIds.push(opp.id));
  opportunitiesListExpected.length &&
    opportunitiesListExpected.filter((opp) => categorizedIds.push(opp.id));
  opportunitiesListUpside.length &&
    opportunitiesListUpside.filter((opp) => categorizedIds.push(opp.id));
  opportunitiesListPipeline.length &&
    opportunitiesListPipeline.filter((opp) => categorizedIds.push(opp.id));

  const opportunitiesListUncategorized = opportunitiesList.filter(
    (opp) => !categorizedIds.includes(opp.id)
  );

  const filteredByForecastCategory = (forcastType) => {
    switch (forcastType) {
      case "Committed":
        setEditedOrderList(opportunitiesListCommited);
        break;
      case "Expected":
        setEditedOrderList(opportunitiesListExpected);
        break;
      case "Upside":
        setEditedOrderList(opportunitiesListUpside);
        break;
      case "Pipeline":
        setEditedOrderList(opportunitiesListPipeline);
        break;
      case "Uncategorized":
        setEditedOrderList(opportunitiesListUncategorized);
        break;
      case "All":
        setEditedOrderList(opportunitiesList);
        break;
    }
  };

  const sortByDate = (direction) => {
    if (editedOrderList) {
      if (direction === "Old first") {
        setEditedOrderList(
          [...editedOrderList].sort(
            (a, b) => new Date(b.close_date) - new Date(a.close_date)
          )
        );
      } else {
        setEditedOrderList(
          [...editedOrderList].sort(
            (a, b) => new Date(a.close_date) - new Date(b.close_date)
          )
        );
      }
    }
  };

  const sortByAmount = (direction) => {
    if (editedOrderList) {
      if (direction === "From lowest to highest") {
        setEditedOrderList(
          [...editedOrderList].sort((a, b) => a.amount - b.amount)
        );
      } else if (direction === "From highest to lowest") {
        setEditedOrderList(
          [...editedOrderList].sort((a, b) => b.amount - a.amount)
        );
      } else {
        filteredByForecastCategory(
          localStorage.getItem("forcastTypeCockpit")
            ? localStorage.getItem("forcastTypeCockpit")
            : "All"
        );
      }
    }
  };

  const dateformating = (date) => {
    let dateList = date.split("-").reverse();
    let result = dateList[1] + "/";
    let iteration = dateList.map((item, index) => {
      if (index !== 1) {
        result += item;
        index !== dateList.length - 1 && (result += "/");
      }
    });
    return result;
  };

  useEffect(() => {
    if (opportunitiesList.length) {
      setEditedOrderList(opportunitiesList);
      filteredByForecastCategory(
        localStorage.getItem("forcastTypeCockpit")
          ? localStorage.getItem("forcastTypeCockpit")
          : "All"
      );
    }
  }, [opportunitiesList]);

  useEffect(() => {
    amountTypeSort && sortByAmount(amountTypeSort);
  }, [amountTypeSort]);

  useEffect(() => {
    dateTypeSort && sortByDate(dateTypeSort);
  }, [dateTypeSort]);

  useEffect(() => {
    forcastType && filteredByForecastCategory(forcastType);
  }, [forcastType]);

  const setForcastTypeWrapper = (type) => {
    localStorage.setItem("forcastTypeCockpit", type);
    setForcastType(type);
  };

  const getCompanyName = (company_id) => {
    let filteredCompany = companiesList.filter(
      (company) => company.id === company_id
    );
    return filteredCompany.length ? filteredCompany[0].name : null;
  };

  const getCasesName = (product_id, cases_id_list) => {
    let filteredCaseByProduct = casesList.filter((oppCase) =>
    oppCase.products_id_list && oppCase.products_id_list.includes(product_id)
    );
    let filteredCase = casesList.filter((oppCase) =>
      cases_id_list.includes(oppCase.id)
    );
    if (filteredCaseByProduct.length) {
      return filteredCaseByProduct[0].name;
    } else if (filteredCase.length) {
      return filteredCase[0].name;
    }
    return null;
  };

  const getProductName = (product_id) => {
    let filteredProduct = productList.filter(
      (product) => product.id === product_id
    );
    if (filteredProduct.length) {
      return filteredProduct[0].name;
    }
    return null;
  };

  const handleOpportunityBlock = (index, company) => {
    let openedOpportunitiesArr = localStorage.getItem("openedOpportunityCockpit") ?
      localStorage.getItem("openedOpportunityCockpit").split(",") :
      []
    let openedOpportunitiesTitleArr = localStorage.getItem("openedOpportunityTitlesCockpit") ?
      localStorage.getItem("openedOpportunityTitlesCockpit").split(",") :
      []

    if(!openedOpportunitiesArr.includes(currentOppotunity.company.id)){

      openedOpportunitiesArr.push(currentOppotunity.company.id)
      let openedOpportunitiesStr = openedOpportunitiesArr.join(",")
      localStorage.setItem("openedOpportunityCockpit", JSON.parse(JSON.stringify(openedOpportunitiesStr)))

      let newIndex = index + 1;
      if (newIndex && opportunityPage && currentOppotunity.company.id) {
        // setCurrentObjective(filteredObjectivesList[index-1])
        return navigate(
          window.open(
            opportunityPage + "/" + currentOppotunity.company.id + "/" + newIndex,
            "_blank"
          )
        );
      }
    }
  };

  return (
    <>
      <div className="hint">Double click on any opportunity to edit it.</div>
      <table className="table dealDashboardMainTable" ref={tableRef}>
        <thead>
          <tr className="firstHead">
            <th>
              <span>Forecast Category</span>
            </th>
            <th className="mainTableFilter">
              <DropdownInput
                data={editedOrderList.length ? amountFilterCategories : []}
                type="mainTableFilter"
                title="Amount "
                setCurrentItem={setAmountTypeSort}
              />
            </th>
            <th className="mainTableFilter">
              <DropdownInput
                data={editedOrderList.length ? closeDateFilterCategories : []}
                type="mainTableFilter"
                title="Close date "
                setCurrentItem={setDateTypeSort}
              />
            </th>
            <th className="nameThreeColorsCol">
              <span>Relationship Strength</span>
            </th>
            <th className="nameThreeColorsCol urgency">
              <span>Urgency</span>
            </th>
            <th className="nameThreeColorsCol">
              <span>Strategic Business Impact</span>
            </th>
          </tr>
          <tr className="secondHead">
            <th className="filterSecondHead">
              <DropdownInput
                data={forecastFilterCategoriesFormated}
                dropDownIcon={ImgBuilder.forcastFilter}
                type="forecast"
                setCurrentItem={setForcastTypeWrapper}
              />
            </th>
            <th></th>
            <th></th>
            <th>
              <div className="scaleThreeColorsCol">
                <div>low</div>
                <div>mid</div>
                <div>high</div>
              </div>
            </th>
            <th className="scaleThreeColorsCol">
              <div className="scaleThreeColorsCol">
                <div>low</div>
                <div>mid</div>
                <div>high</div>
              </div>
            </th>
            <th className="scaleThreeColorsCol">
              <div className="scaleThreeColorsCol">
                <div>low</div>
                <div>mid</div>
                <div>high</div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {editedOrderList.length ? (
            editedOrderList.map((company, index) => (
              <>
                <tr
                  onDoubleClick={() =>
                    handleOpportunityBlock(index, getCompanyName(company.company_id))
                  }
                  key={company.id}
                  onClick={() =>
                    setTimeout(setCurrentOppotunity({ company }), 6000)
                  }
                  className={`${
                    currentOppotunity?.company.id === company.id
                      ? "activeOpportunity"
                      : index < editedOrderList.length - 1 &&
                        editedOrderList[index + 1].id ===
                          currentOppotunity?.company.id
                      ? "beforeActiveOpportunity"
                      : ""
                  }`}
                >
                  <td style={{ position: "relative" }}>
                    <div style={{ paddingRight: "40px" }}>
                      <div className="companyBlackText">
                        {getCompanyName(company.company_id)}
                      </div>
                      <div className="companyDetails">
                        {company?.name ? company?.name : null}
                      </div>
                      <div className="companyDetails">
                        {getCasesName(
                          company?.product_id,
                          company.cases_id_list
                        )}
                      </div>
                      <div className="companyDetails">
                        {getProductName(company?.product_id)}
                      </div>
                    </div>
                  </td>
                  <td className="companyBlackText">{`$${company.amount.toLocaleString(
                    "en-US"
                  )}`}</td>
                  <td className="companyCloseDate">
                    {dateformating(company.close_date)}
                  </td>
                  <td>
                    <div
                      className={`datathreeColors ${company.opportunity_relationships_strength}Color`}
                    >
                      <div className="levelDatathreeColors low"></div>
                      <div className="levelDatathreeColors mid"></div>
                      <div className="levelDatathreeColors high"></div>
                    </div>
                  </td>
                  <td>
                    <div
                      className={`datathreeColors ${company.opportunity_urgency}Color`}
                    >
                      <div className="levelDatathreeColors low"></div>
                      <div className="levelDatathreeColors mid"></div>
                      <div className="levelDatathreeColors high"></div>
                    </div>
                  </td>
                  <td>
                    <div
                      className={`datathreeColors ${company.opportunity_strategic_business_impact}Color`}
                    >
                      <div className="levelDatathreeColors low"></div>
                      <div className="levelDatathreeColors mid"></div>
                      <div className="levelDatathreeColors high"></div>
                    </div>
                  </td>
                </tr>
              </>
            ))
          ) : (
            <tr className="titleNoData">
              <div>There are no opportunities related for this forecast</div>
            </tr>
          )}
        </tbody>
        <tfoot>
          <td className="companyBlackText">
            Total {forcastType?.title ? forcastType?.title : forcastType}
          </td>
          <td className="companyBlackText">{`$${
            editedOrderList.length
              ? totalCommited.toLocaleString("en-US")
              : "0,000"
          }`}</td>
        </tfoot>
      </table>
      <NeedToRefreshAlert tablewidth={tableSizeParams.width} />
    </>
  );
};

export default inject(
  "opportunitiesModel",
  "objectivesModel",
  "authModel",
  "serverAPImodel"
)(observer(DealDashboardMainTable));
