import { height } from "dom-helpers";
import React from "react";
import { inject, observer } from "mobx-react";
import ImgBuilder from "../../../res/img/imgBuilder";

import "./styles.scss";
import { useEffect } from "react";
import { useState } from "react";

const TotalDiagram = (props) => {
  const {
    data,
    objectivesModel: { diagramsData, getObjectivesList },
    opportunitiesModel: { currentOppotunity, companiesList },
    loadingModel: { setLoadingModelState },
  } = props;
  const companylightDiagramTitle = companiesList.filter(
    (company) => company.id == currentOppotunity?.company?.company_id
  ).length
    ? companiesList.filter(
        (company) => company.id === currentOppotunity.company.company_id
      )[0].name
    : "Company name";

  const opportunitylightDiagramTitle = currentOppotunity?.company?.name
    ? currentOppotunity?.company?.name
    : "Opportunity name";

  const totalGoalCount = data.length
    ? data.length === 1
      ? 0
      : data.length
    : 4;
  const totalAnswered = data.length
    ? data.reduce((sum, current) => {
        return (sum = sum + current.answeredQuestionCount);
      }, 0)
    : 0;
  const totalQuestions = data.length
    ? data.reduce((sum, current) => {
        return (sum = sum + current.totalQuestionCount);
      }, 0)
    : 0;

  const diagramTitle = "Score";
  const devidersAngles = [];

  for (let i = 0; i < totalGoalCount; i++) {
    i
      ? devidersAngles.push((360 / totalGoalCount) * i)
      : devidersAngles.push(0);
  }
  const diagramAngle = 360 / totalGoalCount;
  const answersData = diagramsData.map((diagram) => {
    return {
      totalQuestionCount: diagram.totalQuestionCount,
      answeredQuestionCount: diagram.answeredQuestionCount,
      default: diagram.questionInfo.reduce((total, question) => {
        return total + (question.answers[0].state === 0 ? 1 : 0);
      }, 0),
      low: diagram.questionInfo.reduce((total, question) => {
        return total + (question.answers[0].state === 1 ? 1 : 0);
      }, 0),
      hight: diagram.questionInfo.reduce((total, question) => {
        return total + (question.answers[0].state === 2 ? 1 : 0);
      }, 0),
    };
  });

  const calculateWidth = (goal, priority, currentItemId) => {
    switch (priority) {
      case "default":
        return 62 + 81 * (currentItemId / goal.totalQuestionCount);
      case "low":
        return (
          62 + 81 * ((goal.default + currentItemId) / goal.totalQuestionCount)
        );
      case "hight":
        return (
          62 +
          81 *
            ((goal.default + goal.low + currentItemId) /
              goal.totalQuestionCount)
        );
    }

    return 62 + 80 * (goal.answeredQuestionCount / goal.totalQuestionCount);
  };

  const calculateZindex = (priority, sector) => {
    switch (priority) {
      case "default":
        return 300 - sector;
      case "low":
        return 200 - sector;
      case "hight":
        return 100 - sector;
    }
  };

  const createArrayLength = (arrayLength) => {
    let result = [];
    for (let i = 1; i < arrayLength + 1; i++) {
      result.push(i);
    }
    return result;
  };

  const handleRefresh = () => {
    setLoadingModelState(true);
    getObjectivesList();
  };

  const fillingDiagramPart = (priority, goal, sector, sectorIndex) => {
    return devidersAngles.length > 1 ? (
      <div
        className={`fillMint ${priority}`}
        style={{
          transform: `rotate(${180 - diagramAngle}deg)`,
          width: `${calculateWidth(goal, priority, sector)}px`,
          height: `${calculateWidth(goal, priority, sector)}px`,
          clip: `rect(0px, ${calculateWidth(
            goal,
            priority,
            sector
          )}px, ${calculateWidth(goal, priority, sector)}px, ${
            calculateWidth(goal, priority, sector) / 2 - 1
          }px)`,
          border: `${
            goal.totalQuestionCount > 7 &&
            goal[priority] - 1 !== sectorIndex
              ? "unset"
              : "2px solid #1E74EC"
          }`,
        }}
      ></div>
    ) : (
      <div className={`circle ${priority}`}></div>
    )
  }

  const mainColorDiagramPart = (priority, goal, sector, sectorIndex, index, secondHalf = false) => {
    let rotateAngle = diagramAngle - (180 - index * diagramAngle)
    return <div
    className="maskMint fullMint"
    key={sectorIndex}
    style={{
      transform: `rotate(${
        secondHalf ? 180+rotateAngle : rotateAngle
      }deg)`,
      width: `${calculateWidth(goal, priority, sector)}px`,
      height: `${calculateWidth(goal, priority, sector)}px`,
      marginTop: `${
        (140 - calculateWidth(goal, priority, sector)) / 2
      }px`,
      marginLeft: `${
        (140 - calculateWidth(goal, priority, sector)) / 2
      }px`,
      clip: `${
        devidersAngles.length > 1 &&
        `rect(0px, ${calculateWidth(
          goal,
          priority,
          sector
        )}px, ${calculateWidth(goal, priority, sector)}px, ${
          calculateWidth(goal, priority, sector) / 2 - 1
        }px)`
      }`,
      zIndex: calculateZindex(priority, sector),
    }}
  >
    {fillingDiagramPart(priority, goal, sector, sectorIndex)}
  </div>
  }

  return (
    <div className="containerTotalDiagram">
      <div className="circleWrapTotalDiagram">
        <div className="circleTotalDiagram">
          {answersData.map((goal, index) =>
            ["default", "low", "hight"].map((priority) =>
              createArrayLength(goal[priority]).map((sector, sectorIndex) => answersData.length === 1 ?
                <>
                  {mainColorDiagramPart(priority, goal, sector, sectorIndex, index)}
                  {mainColorDiagramPart(priority, goal, sector, sectorIndex, index, true)}
                </> : 
                mainColorDiagramPart(priority, goal, sector, sectorIndex, index)
              )
            )
          )}
          <div className="maskGrey fullGrey">
            <div className="fillGrey"></div>
          </div>
          <div className="maskGrey halfGrey">
            <div className="fillGrey"></div>
          </div>
          {devidersAngles.length > 1 &&
            devidersAngles.map((angle) => (
              <div
                className="circleDevider"
                style={{ transform: `rotate(${angle}deg)` }}
              ></div>
            ))}
          <div className="insideCircle">
            {totalAnswered
              ? Math.round((totalAnswered / totalQuestions) * 100)
              : 0}
            <span>%</span>
          </div>
        </div>
        <div className="titleTotalDiagram">{diagramTitle}</div>
      </div>
      <div className="rightTitleTotalDiagram">
        <span
          className="companyNameTotalDiagram"
          style={{
            display: "flex",
            flexDirection: `${
              companylightDiagramTitle.length +
                opportunitylightDiagramTitle.length +
                3 >
              24
                ? "column"
                : "row"
            }`,
            whiteSpace: `${
              companylightDiagramTitle.length < 25 &&
              opportunitylightDiagramTitle.length < 25
                ? "nowrap"
                : "inherit"
            }`,
            display: `${answersData.length ? "inherit" : "none"}`,
          }}
        >
          <span>{companylightDiagramTitle}
            <span className="slash">&nbsp;/&nbsp;</span>
          </span>
          <span>{opportunitylightDiagramTitle}</span>
        </span>
        <span>Total Information Position</span>
        <div className="refreshButton" onClick={() => handleRefresh()}>
          <img src={ImgBuilder.brightReloadIcon} />
          <span>Refresh</span>
        </div>
      </div>
    </div>
  );
};

export default inject(
  "objectivesModel",
  "opportunitiesModel",
  "loadingModel"
)(observer(TotalDiagram));
