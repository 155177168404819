import React, {useEffect, useRef, useState} from "react";
import "../../res/consts/global_styles.scss";
import Header from "../../components/Header";
import { observer, inject } from "mobx-react";
import { useParams } from "react-router";

import "./styles.scss";
import Loader from "../../components/Loader";
const BasicPage = (props) => {
  const authToken = localStorage.getItem("auth_token_cockpit");
  const {
    children,
    objectivePage = false,
    authModel: { tableSizeParams, setScreenWidth, setTableSizeParams},
  } = props;
  let { id, companyName } = useParams();

  const ref = useRef(null);
  const [customStyles, setCustomStyles] = useState({});
  const [customStylesShdow, setCustomStylesShdow] = useState({});
  const [scaleStyles, setScaleStyles] = useState("")

  const handleHeight = () => {
    let stylesObject = {};
    const windowSize = window.innerWidth;

    if (ref && ref.current && !companyName) {
      const tableHeight = ref.current.clientHeight;

      stylesObject.height = windowSize <= 1320 ? `${(tableHeight * windowSize/1302) + 40}px` : `${tableHeight + 40}px`;
    } else {
      stylesObject.minHeight = "auto";
    }

    setCustomStyles(stylesObject);
  };

  useEffect(() => {
    function handleResize() {
      let windowSize = window.innerWidth
      let stylesObjectShdow = windowSize <= 1320 ?{
        width:`${Math.round((1/(windowSize/1320))*100)}%`,
        marginLeft:`-${(Math.round((1/(windowSize/1320))*100) - 100)/2}%`,
        transformOrigin:"top"
      }: {};

      setTableSizeParams({width:`${Math.round((1/(windowSize/1320))*100)}`});
      windowSize <= 1320 && setScaleStyles(`scale(${(windowSize/1320)})`)
      windowSize > 1320 && setScaleStyles(`scale(1)`);

      if (companyName && !objectivePage) {
        stylesObjectShdow.border = "unset";
        stylesObjectShdow.boxShadow = "unset";
        stylesObjectShdow.borderRadius = "unset";
        stylesObjectShdow.paddingBottom = "40px";
      }else {
        stylesObjectShdow.border = "1px solid #EAEEF1";
        stylesObjectShdow.boxShadow = "0px 2px 10px rgba(186, 188, 201, 0.5)";
        stylesObjectShdow.borderRadius = "5px";
      }
      if(windowSize > 1200){
        setScreenWidth(windowSize)
      }
      setCustomStylesShdow(stylesObjectShdow);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleHeight);
    return () => window.removeEventListener("resize", handleHeight);
  }, []);

  useEffect(() => {
    handleHeight();
  }, [tableSizeParams, companyName]);

  return (
    <>
      <Header/>
      <div
          style={customStyles}
          className="container basicPageContainer"
        >
          <div ref={(node) => {
              if (node) {
                node.style.setProperty("transform", `${scaleStyles}`, "important");
                node.style.setProperty("-webkit-transform", `${scaleStyles}`, "important");
                node.style.setProperty("transform-origin", "top", "important");
              }
            }}>    
            <div ref={ref} style={customStylesShdow} className={`${(companyName && !objectivePage) ? "" : "p-3"}`}>
              {children}
            </div>
          </div>
      </div>
      <Loader/>
    </>
  );
};

export default inject("authModel")(observer(BasicPage));
