import React, {useEffect, useState, useReducer, useRef} from "react"
import { inject, observer } from "mobx-react"
import "./styles.scss"
import ImgBuilder from "../../../res/img/imgBuilder"

const CardCarouselOpportunity = (props) => {
    const {
        data,
        opportunitiesModel: {
            currentOppotunity
        },
        authModel:{
            screenWidthState
        }
    } = props
    const [cardWidth, setCardWidth] = useState(window.innerWidth < 749 ? 190 : 160);
    const [cardsPerSlide, setCardsPerSlide] = useState(0);
    const [transform, setTransform] = useState(0)
    const [lastItemId, setLastItemId] = useState(data.length > cardsPerSlide ? cardsPerSlide : data.length);
    const [dataLength, setDataLength] = useState(data.length)
    const ref = useRef(null);

    const resizeHandler = () => {
        let currentCardWidth = cardWidth;

        if(window.innerWidth < 749 && cardWidth !== 200){
            setCardWidth(200);
            setTransform(0);
            setLastItemId(data.length);
            currentCardWidth = 200;
        } else if (window.innerWidth >= 749 && cardWidth !== 160){
            setCardWidth(160);
            setTransform(0);
            setLastItemId(data.length);
            currentCardWidth = 160;
        }

        if(ref.current && ref.current.offsetWidth) {
            const currentNumberOfCardsPerSlide = Math.trunc(ref.current.offsetWidth / currentCardWidth);

            setCardsPerSlide(prevNumberOfCardsPerSlide => {
                if(prevNumberOfCardsPerSlide !== currentNumberOfCardsPerSlide && transform !== 0) {
                    setLastItemId(lastItemId - (prevNumberOfCardsPerSlide - currentNumberOfCardsPerSlide))
                }
                return currentNumberOfCardsPerSlide;
            });
        }
    };

    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        resizeHandler();
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, [screenWidthState, transform, lastItemId]);

    useEffect(() => {
        if(data){
            setLastItemId((value) => {
                if(value >= cardsPerSlide && transform !== 0){
                    return value;
                } else {
                    return data.length > cardsPerSlide ? cardsPerSlide : data.length;
                }
            });
            setDataLength(data.length);
        }
    }, [data, currentOppotunity, cardsPerSlide, screenWidthState])

    const handleSwipe = (side) =>{
        if(side === "right") {
            setTransform(prevTransform => prevTransform - cardWidth)
            setLastItemId(parseInt(lastItemId) + 1)

        } else{
            setTransform(prevTransform => prevTransform + cardWidth)
            setLastItemId(parseInt(lastItemId) - 1)
        }
    }

    return <div className = "containerCardCarouselOpport" ref={ref}>
        <div className = "arrowsCardCarouselOpport">
            {(lastItemId - cardsPerSlide > 0 && (dataLength ? true : null)) &&
                <img src = {ImgBuilder.arrowLeft}
                     className = "arrowLeftCardCarouselOpport"
                     onClick = {() => handleSwipe("left")}
                />
            }
            {((dataLength ? true : null) && lastItemId < dataLength) &&
                <img src = {ImgBuilder.arrowRight}
                     className = "arrowRightCardCarouselOpport"
                     onClick = {() => handleSwipe("right")}
                />
            }
        </div>
        <div className = "overflowCardCarouselOpport"
            style={{width:`${cardWidth*cardsPerSlide}px`,
                    margin: cardsPerSlide > dataLength ? "0px" : "0 auto"}}>
            <div className = "carouselCardCarouselOpport"
                 style = {{transform:`translateX(${transform}px)`,}}>
                 {data}
            </div>
        </div>
    </div>
}

export default inject(
    "opportunitiesModel",
    "authModel"
)(observer(CardCarouselOpportunity))