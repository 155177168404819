import React, { useEffect, useState } from "react";
import CardCarousel from "../../components/CardCarousel";
import SingleObjectiveDiagram from "../../components/ObjectiveDiagrams/SingleObjectiveDiagram";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import { homeUrl } from "../../res/consts/router_urls_const";
import GoBackButton from "../../components/GoBackButton";
import { inject, observer } from "mobx-react";
import NeedToRefreshAlert from "../../components/NeedToRefreshAlert";

import "./styles.scss";

const ObjectiveDetailsPage = (props) => {
  const {
    objectivesModel: {
      currentObjective,
      filteredObjectivesList,
      diagramsData,
      setCurrentObjective,
      objectivesList,
      getObjectivesList,
    },
    opportunitiesModel: { opportunitiesList, setCurrentOppotunity },
    authModel: { currenManager, tableSizeParams, needToRefresh },
    loadingModel: { setLoadingModelState },
  } = props;

  let { id, companyName } = useParams();

  const [width, setWidth] = useState(0);
  const [currentObjectiveState, setCurrentObjectiveState] = useState([]);
  const [unAnsweredQuestions, setUnAnsweredQuestions] = useState([]);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [reloadPage, setReloadPage] = useState(false);
  const [currentDiagramId, setCurrentDiagramId] = useState(id);
  const [filteredObjectivesListState, setFilteredObjectivesListState] =
    useState(filteredObjectivesList);
  const [diagramsDataState, setDiagramsDataState] = useState(diagramsData);

  const navigate = useNavigate();

  const handleGoBack = () => {
    if (needToRefresh) {
      setLoadingModelState(true);
      getObjectivesList();
    }
    return navigate(homeUrl);
  };

  const handleResize = () => {
    if (tableSizeParams) {
      setWidth(tableSizeParams.width);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!currenManager?.id) {
      setReloadPage(true);
    }

    return (() => {
      !currenManager?.id && navigate(homeUrl);
    })();
  }, []);

  useEffect(() => {
    if(reloadPage &&
        opportunitiesList.length &&
        objectivesList.length &&
        Object.keys(currenManager).includes("id")){

      setCurrentOppotunity({
        company: opportunitiesList.filter(
            (opportunity) =>
                opportunity.id ===
                JSON.parse(localStorage.getItem("currentOppotunityIdCockpit"))
        )[0],
      });
    }

  }, [opportunitiesList, objectivesList]);

  useEffect(() => {
    if (reloadPage && filteredObjectivesList.length) {
      setCurrentObjective(
        filteredObjectivesList.filter(
          (objective) =>
            objective.id ===
            JSON.parse(localStorage.getItem("currentObjectiveIdCockpit"))
        )[0]
      );
      let objectivesIteration = filteredObjectivesList.map(
        (objective, index) => {
          if (
            objective.id ===
            JSON.parse(localStorage.getItem("currentObjectiveIdCockpit"))
          ) {
            setCurrentDiagramId(index + 1);
          }
        }
      );
      setFilteredObjectivesListState(filteredObjectivesList);
    }
  }, [filteredObjectivesList]);

  useEffect(() => {
    if (currentObjective?.topics && diagramsData.length) {
      let draftCurrentObjectiveState = diagramsData.filter(
        (diagram) => diagram.id === currentObjective.id
      )[0].questionInfo;
      setCurrentObjectiveState(draftCurrentObjectiveState);
      setUnAnsweredQuestions(
        draftCurrentObjectiveState
          .filter((question) => !question.answers[0].text)
          .sort((a, b) => a.position - b.position)
      );
      let answeredQuestionsUnordered = draftCurrentObjectiveState.filter(
        (question) => question.answers[0].text
      );
      let answeredQuestionsOrdered = [];
      let answersListLength = answeredQuestionsUnordered.length;
      if (answersListLength) {
        for (let i = 0; i < answersListLength; i++) {
          let theLatestAnswer = answeredQuestionsUnordered[0];
          let theLatestAnswerIndex = 0;
          answeredQuestionsUnordered.map((item, index) => {
            if (
              item.answers[0].dateWithoutFormating >
              theLatestAnswer.answers[0].dateWithoutFormating
            ) {
              theLatestAnswer = item;
              theLatestAnswerIndex = index;
            }
          });
          answeredQuestionsOrdered.push(theLatestAnswer);
          answeredQuestionsUnordered.splice(theLatestAnswerIndex, 1);
        }
      }
      answeredQuestionsOrdered.sort((a,b) => b.position - a.position)
      setAnsweredQuestions(answeredQuestionsOrdered);
    }
  }, [currentObjective, diagramsData]);

  useEffect(() => {
    diagramsData.length && setDiagramsDataState(diagramsData);
  }, [diagramsData]);

  return (
    <>
      <div className="row containerObjectiveDetailsPage ms-1 me-1">
        <div className="col-3 diagramObjectiveDetailsPage p-4">
          <CardCarousel
            cardsPerSlide={1}
            cardWidth={150}
            objectiveDetailsFirstSlide={currentDiagramId}
            type="objectiveDetails"
            functionOnSliding={setCurrentObjective}
            filteredObjectivesList={filteredObjectivesListState}
            data={diagramsDataState.map((objective, objectiveIndex) => (
              <SingleObjectiveDiagram
                diagramTitle={objective.title}
                totalQuestionCount={objective.totalQuestionCount}
                answeredQuestionCount={objective.answeredQuestionCount}
                index={objectiveIndex + 1}
              />
            ))}
          />
        </div>
        <div className="col-9 ps-5 questionsContainerObjectiveDetailsPage">
          <div className="row mb-4">
            <GoBackButton
              text="← Back to Overview"
              goBackFunction={handleGoBack}
            />
          </div>
          <div className="row questionsObjectiveDetailsPage">
            <div className="col-5">
              <div className="columnTopObjectiveDetailsPage pb-2">
                <div className="columnTitleObjectiveDetailsPage">
                  Unanswered
                </div>
                <div className="columnCountObjectiveDetailsPage">
                  {unAnsweredQuestions.length}
                </div>
              </div>
              {unAnsweredQuestions.map((question) => (
                <div>
                  <hr className="dropdown-divider" />
                  <p className="pt-2 pb-2">{question.questionText}</p>
                </div>
              ))}
              <hr className="dropdown-divider" />
            </div>
            <div className="col-7">
              <div className="columnTopObjectiveDetailsPage">
                <div className="columnTitleObjectiveDetailsPage">Answered</div>
                <div className="columnCountObjectiveDetailsPage">
                  {answeredQuestions.length}
                </div>
              </div>
              {answeredQuestions.map((question) => (
                <div>
                  <p className="pt-2 pb-2">{question.questionText}</p>
                  {question.answers.map((answer) => (
                    <div
                      className={`answerContainerObjectiveDetailsPage AnswerColor${answer.state} p-2 mb-2`}
                    >
                      <p>{answer.text}</p>
                      <div className="answerDetails row">
                        <div className="col-auto me-auto">{answer.name}</div>
                        <div className="col-auto">{answer.date}</div>
                      </div>
                    </div>
                  ))}
                  <hr className="dropdown-divider mt-4" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <NeedToRefreshAlert
        tablewidth={width}
        page="objectiveDetails"
      />
    </>
  );
};

export default inject(
  "objectivesModel",
  "opportunitiesModel",
  "authModel",
  "loadingModel"
)(observer(ObjectiveDetailsPage));
