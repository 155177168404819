import { inject, observer} from 'mobx-react';
import './App.css';
import MainRouter from './router/MainRouter';

function App() {
  return MainRouter
}


export default inject(
  "opportunitiesModel",
  "objectivesModel",
  "authModel",
  "serverAPImodel",
)(observer(App))
