import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import ImgBuilder from "../../../res/img/imgBuilder";

import "./styles.scss";
import { toJS } from "mobx";

const SingleOpportDiagram = (props) => {
  const {
    isMicrosoftEdgeBrowser,
    totalQuestionCount,
    answeredQuestionCount,
    objectiveId,
    key,
    diagramTitle,
    detailsUrl = false,
    type = "simple",
    // isActive = false, // TODO
    index = false,
    currentCompany = false,
    objectivesModel: {
      objectivesList,
      setCurrentObjective,
      filteredObjectivesList,
      diagramsDataAfterUpdate,
      addDiagramInDiagramsDataAfterUpdate,
      deleteDiagramInDiagramsDataAfterUpdate,
      diagramsData,
    },
  } = props;

  const [answersDataState, setAnswersDataState] = useState([]);

  useEffect(() => {
    if (index && diagramsData[index - 1]?.questionInfo) {
      let answersDataStateDraft = diagramsData[index - 1].questionInfo.filter(
        (question) => question.answers[0]?.text
      );
      let greenQuestions = answersDataStateDraft.filter(
        (question) => question.answers[0].state === 0
      );
      let pinkQuestions = answersDataStateDraft.filter(
        (question) => question.answers[0].state === 1
      );
      let redQuestions = answersDataStateDraft.filter(
        (question) => question.answers[0].state === 2
      );
      let result = []
        .concat(greenQuestions)
        .concat(pinkQuestions)
        .concat(redQuestions);
      setAnswersDataState(result);
    } else {
      setAnswersDataState([]);
    }
  }, [diagramsData]);

  // useEffect(() => {
  //   if (index && diagramsData[index - 1]?.questionInfo) {
  //     let answersDataStateDraft = diagramsData[index - 1].questionInfo.filter(
  //       (question) => question.answers[0]?.text
  //     );

  //   } else {
  //     setAnswersDataState([]);
  //   }
  // }, [diagramsData]);

  const devidersAngles = [];
  for (let i = 0; i < totalQuestionCount; i++) {
    i
      ? devidersAngles.push((360 / totalQuestionCount) * i)
      : devidersAngles.push(0);
  }
  const diagramAngle = 360 / totalQuestionCount;

  const handleDeleteSelectedOpportDiagram = (ev, diagramId) => {
    ev.stopPropagation();
    deleteDiagramInDiagramsDataAfterUpdate(diagramId);
  };

  const isActive = toJS(diagramsDataAfterUpdate).find(
    (item) => item.id === objectiveId
  );
  return (
    <div
      key={key}
      className={
        isActive
          ? "containerSingleOpportDiagramOpp col-2 activColor"
          : "containerSingleOpportDiagramOpp col-2"
      }
      onClick={() => addDiagramInDiagramsDataAfterUpdate(objectiveId)}
    >
      <div className={isActive ? "clousAnswerPanel" : "closeedCaruse"}>
        <div className="closeImgeContainer">
          <img
            className="closeImge"
            onClick={(ev) => handleDeleteSelectedOpportDiagram(ev, objectiveId)}
            src={ImgBuilder.blueCrossIcon}
          />
        </div>
      </div>
      <div className="circleWrapSingleOpportDiagramOpp">
        <div className="circleSingleOpportDiagramOpp">
          {diagramAngle > 0 &&
            answersDataState.map((answerItem, answerIndex) => (
              <div key={answerIndex}>
                <div
                  className="maskMintOpport fullMintOpport"
                  key={answerItem.answers[0].color}
                  style={{
                    transform: `rotate(${diagramAngle * answerIndex}deg)`,
                    clip: `rect(0px, 120px, 120px, ${
                      totalQuestionCount === 1 ? "0" : "59"
                    }px)`,
                  }}
                >
                  {totalQuestionCount > 1 ? (
                    <div
                      className={`fillMintOpport AnswerColor${answerItem.answers[0].state}`}
                      style={{
                        transform: `rotate(${diagramAngle}deg)`,
                      }}
                    ></div>
                  ) : (
                    <div
                      className={`circle AnswerColor${answerItem.answers[0].state}`}
                    ></div>
                  )}
                </div>
              </div>
            ))}

          <div className="maskGreyOpport fullGreyOpport smallСircle">
            <div className="fillGreyOpport smallСircle"></div>
          </div>
          <div className="maskGreyOpport halfGreyOpport smallСircle">
            <div className="fillGreyOpport smallСircle"></div>
          </div>
          {devidersAngles.length > 1 &&
            devidersAngles.map((angle, angIndex) => (
              <div
                className="circleDeviderOpport"
                style={{ transform: `rotate(${angle}deg)` }}
                key={angIndex}
              ></div>
            ))}
          <div
            className={
              isActive
                  ? (isMicrosoftEdgeBrowser ? "activColorDiagramInsaide microsoftEdgeBrowser":"activColorDiagramInsaide")
                  : (isMicrosoftEdgeBrowser ? "insideCircleOpport microsoftEdgeBrowser":"insideCircleOpport")
            }
            style = {{
              color: `${(answeredQuestionCount === 0 && totalQuestionCount === 0)? "#6DB6FE" : "white"}`
            }}
          >
            {answeredQuestionCount}/{totalQuestionCount}
          </div>
        </div>
      </div>
      <div className={isMicrosoftEdgeBrowser? "titleSingleObjectiveDiagram microsoftEdgeBrowser": "titleSingleObjectiveDiagram"}>{diagramTitle}</div>
    </div>
  );
};

export default inject(
  "objectivesModel",
  "opportunitiesModel"
)(observer(SingleOpportDiagram));
