import { observable, makeObservable, makeAutoObservable, action } from 'mobx'
import axios from 'axios'
import { serverAPImodel } from './serverAPI'
import { objectivesModel } from './objectives'

export class AuthModel {
    constructor(){
        this.auth_token = ""
        this.loading = true
        this.currenManager = {}
        this.serverErrors = {email:"" , password: ""}
        this.tableSizeParams = {width:0, height:0}
        this.needToRefresh = false
        this.screenWidthState = window.innerWidth
        makeAutoObservable(this)
    }

    setScreenWidth = width => {
        this.screenWidthState = width
    }

    setNeedToRefresh = (data) => {
        this.needToRefresh = data
    }
    
    setTableSizeParams = data => {
        this.tableSizeParams = data
    }

    setAuthToken = (auth_token) =>{
        this.auth_token = auth_token
        localStorage.setItem("auth_token_cockpit", auth_token)
    }

    setCurrentManager = (currenManager) =>{
        this.currenManager = currenManager
    }

    getToken = (data) =>{
        const setAuthToken = this.setAuthToken
        const setErrors = this.setErrors
        this.serverErrors = {email:"" , password: ""}
        axios.post(`${serverAPImodel.baseAPILogin}try_api_login`, data)
        .then(function (response) {
            setAuthToken(response.data.auth_token)
            window.location.reload()
        })
        .catch(function (error) {
            let errorMessage = error.response.data.error
            errorMessage.includes("email") && setErrors("email")
            errorMessage.includes("password") && setErrors("password")
            errorMessage.includes("Internal Server Error") && setErrors("email")
        })
    }
 
    signIn = (data) =>{
        this.serverErrors = {email:"" , password: ""}
        const getToken = this.getToken
        const setErrors = this.setErrors
        axios.post(`${serverAPImodel.baseAPILogin}`, data)
        .then(function (response) {
            getToken(data)
        })
        .catch(function (error) {
            let errorMessage = error.response.data.error
            errorMessage.includes("email") && setErrors("email")
            errorMessage.includes("password") && setErrors("password")
            errorMessage.includes("Internal Server Error") && setErrors("email")
        })
    }

    setErrors = (type) =>{
        if(type === "password"){this.serverErrors.password = "Wrong password"}
        if(type === "email"){this.serverErrors.email = "Incorrect email"}
    }

    setErrorsEmpty = () =>{
        this.serverErrors = {email:"" , password: ""}
    }

    getCurrentManeger = () =>{
        const setCurrentManager = this.setCurrentManager
        const headers = {
            "headers":{
                "accept": "application/json, text/plain, */*",
                "auth-token": authModel.auth_token.length ? authModel.auth_token : localStorage.getItem("auth_token_cockpit")
            }
        }
        axios.get(serverAPImodel.baseAPIManagerInfo, headers)
        .then(function (response) {
            setCurrentManager(response.data)
            objectivesModel.getObjectivesList()

        })
        .catch(function (error) {
            console.log(error)
        })
    }



    logout = () =>{
        this.auth_token = ""
        localStorage.clear()
        window.location.reload()
    }
}

export const authModel = new AuthModel()

