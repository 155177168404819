import React, {useRef, useEffect, useState} from "react"
import ImgBuilder from "../../res/img/imgBuilder"
import { useFormik, Field, Formik, Form, ErrorMessage } from "formik"
import SimpleInput from "../../components/FormFields/SimleInput"
import GoBackButton from "../../components/GoBackButton"
import * as yup from 'yup'
import ResetPasswordSchema from "../../res/validationSchemes/resetPassword"
import { useNavigate } from "react-router"
import { mailLink } from "../../res/consts/router_urls_const"

import "./styles.scss"

const ResetPasswordPage = () => {
    const [mismatchError, setMismatchError] = useState("")
    
    const customValidate = values => {
        const errors = {}
        mismatchError && (errors.mismatchError = "Password mismatch")
        return errors
    }

    return <div className = "pageContainerResetPasswordPage" style = {{background: `url(${ImgBuilder.backgroundLogo})`}}>
        <div className = "containerResetPasswordPage">
            <div className = "titleResetPasswordPage">Reset Pasword</div>
            <Formik
                initialValues = {{newPassword: "", confirmNewPassword:""}}
                onSubmit = { values => {
                    if(values.newPassword !== values.confirmNewPassword){
                        setMismatchError("Password mismatch")
                    }else{
                        setMismatchError("")
                    }
                }}
                validationSchema = {ResetPasswordSchema}
                //validate = {customValidate}
            >{({ errors, touched }) => (
                <Form>
                    <SimpleInput type = "password" name = "newPassword" label = "New Password" width = {389} required = {true}/>
                    <SimpleInput 
                        type = "password" 
                        name = "confirmNewPassword" 
                        label = "Confirm New Password" 
                        width = {389} 
                        required = {true} 
                        serverError = {mismatchError}
                        setErrorsEmpty = {() => setMismatchError("")}
                    />
                    <div className = "actionsLoginPage">
                        <button type="submit">Save Password</button>
                    </div>
                </Form>
            )}
            </Formik>
        </div>
    </div>
}

export default ResetPasswordPage