import * as yup from 'yup'

const ResetPasswordSchema = yup.object().shape({
    newPassword: yup.string()
        .min(6, 'Password is too short. Password must be at least 6 characters long')
        .max(48, 'Password is too long. Password must be less then 48 characters long')
        .matches(/^(?=(.*[a-zA-Z]){1,})(?=(.*[\d]){1,}).{6,48}$/, 'Wrong password')
        .required('Required'),
    confirmNewPassword: yup.string()
        .matches(/^(?=(.*[a-zA-Z]){1,})(?=(.*[\d]){1,}).{6,48}$/, 'Wrong password')
        .required('Required'),
});

export default ResetPasswordSchema