import React, {useEffect, useState, useReducer, useRef} from "react"
import ImgBuilder from "../../res/img/imgBuilder"
import { inject, observer } from "mobx-react"
import "./styles.scss"

const CardCarousel = (props) => {
    const {
        cardWidth,
        data,
        alignment = "start",
        objectiveDetailsFirstSlide = 0,
        type,
        functionOnSliding,
        filteredObjectivesList,
        opportunitiesModel:{
            currentOppotunity
        },
        authModel:{
            screenWidthState
        }
    } = props
    const [transform, setTransform] = useState(0)
    const [cardsPerSlide, setCardsPerSlide] = useState(0);

    //count of ID from 1
    const [lastItemId, setLastItemId] = useState(data.length > cardsPerSlide ? cardsPerSlide : data.length)
    const [dataLength, setDataLength] = useState(data.length)
    const ref = useRef(null);

    const resizeHandler = () => {
        if(type === "objectiveDetails") {
            setCardsPerSlide(1);
            return;
        }

        if(ref.current && ref.current.offsetWidth) {
            const currentNumberOfCardsPerSlide = Math.trunc(ref.current.offsetWidth / cardWidth);

            setCardsPerSlide(prevNumberOfCardsPerSlide => {
                if(prevNumberOfCardsPerSlide !== currentNumberOfCardsPerSlide && transform !== 0) {
                    setLastItemId(lastItemId - (prevNumberOfCardsPerSlide - currentNumberOfCardsPerSlide))
                }
                return currentNumberOfCardsPerSlide;
            });
        }
    };

    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        resizeHandler();
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, [screenWidthState, transform, lastItemId]);

    useEffect(() => {
        if(data){
            setLastItemId((value) => {
                if(value >= cardsPerSlide && transform !== 0 ){
                    return value;
                } else {
                    return data.length > cardsPerSlide ? cardsPerSlide : data.length;
                }
            });
            setDataLength(data.length);
        }
    }, [data, currentOppotunity, cardsPerSlide, screenWidthState])

    useEffect(()=>{
        if(objectiveDetailsFirstSlide){
            setLastItemId(objectiveDetailsFirstSlide)
            setTransform(-(objectiveDetailsFirstSlide-1)*cardWidth)
        }else if(type !== "objectiveDetails"){
            setLastItemId(objectiveDetailsFirstSlide)
            setTransform(0)
        }
    },[objectiveDetailsFirstSlide, currentOppotunity])

    const handleSwipe = (side) =>{
        if(side === "right"){
            const hideCardsCounter = dataLength - lastItemId;
            if(hideCardsCounter >= cardsPerSlide ) {
                setTransform(prevTransform => prevTransform - cardsPerSlide * cardWidth);
                type === "objectiveDetails" &&
                functionOnSliding(filteredObjectivesList[parseInt(lastItemId)])
                setLastItemId(parseInt(lastItemId) + cardsPerSlide)
            } else {
                setTransform(prevTransform => prevTransform - hideCardsCounter*cardWidth);
                type === "objectiveDetails" &&
                functionOnSliding(filteredObjectivesList[parseInt(lastItemId)])
                setLastItemId(parseInt(lastItemId) + hideCardsCounter)
            }

        }else{
            const hideCardsCounter = lastItemId - cardsPerSlide;
            if(hideCardsCounter >= cardsPerSlide ) {
                setTransform(prevTransform => prevTransform + cardsPerSlide * cardWidth)
                type === "objectiveDetails" &&
                functionOnSliding(filteredObjectivesList[parseInt(lastItemId) - 2])
                setLastItemId(parseInt(lastItemId) - cardsPerSlide)
            } else {
                setTransform(prevTransform => prevTransform + hideCardsCounter*cardWidth)
                type === "objectiveDetails" &&
                functionOnSliding(filteredObjectivesList[parseInt(lastItemId) - 2])
                setLastItemId(parseInt(lastItemId) - hideCardsCounter)
            }
        }
    }

    return <div className = "containerCardCarousel" ref={ref}>
        <div className = "arrowsCardCarousel">
            {(lastItemId - cardsPerSlide > 0 && (dataLength ? true : null)) &&
                <img src = {ImgBuilder.arrowLeft}
                    className = "arrowLeftCardCarousel"
                    style = {{top:"calc(50% - 23px)"}}
                    onClick = {() => handleSwipe("left")}
                />
            }
            {((dataLength ? true : null) && lastItemId < dataLength) &&
                <img src = {ImgBuilder.arrowRight}
                    className = "arrowRightCardCarousel"
                    style = {{top:"calc(50% - 23px)"}}
                    onClick = {() => handleSwipe("right")}
                />
            }
        </div>
        <div className = "overflowCardCarousel"
             style = {{width:`${cardWidth*cardsPerSlide}px`}}>
            <div className = "carouselCardCarousel" style = {{
                transform:`translateX(${transform}px)`,
                justifyContent:`${alignment === "start" ? "flex-start" : (data.length > cardsPerSlide ? "flex-start" : "flex-end")}`
            }}>
                {data}
            </div>
        </div>
    </div>
}

export default inject(
    "opportunitiesModel",
    "authModel"
)(observer(CardCarousel))
