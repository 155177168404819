import React, { useEffect, useMemo, useState } from "react";
import { observer, inject } from "mobx-react";
import TextAreaAnswered from "./TextAreaAnswered";
import { useParams } from "react-router-dom";

import "./styles.scss";

const Answered = ({
  questionsModel :{
    saveQuestionAnswer,
    focusedAnswerId,
    setPositionOfNewAnswerQuestion,
    setFocusedAnswerId
  },
  isMicrosoftEdgeBrowser,
  diagramsDataAfterUpdate,
  answeredQuestions = [],
  priorityValue,
  focusedAreaIndex,
  handleTextareaClick,
  handleTextareaBlur,
  objectiveId
}) => {
  const [showTextAreaOrAnswer, setShowTextAreaOrAnswer] = useState(false);
  const [showTextAreaOrAnswerUbdate, setShowTextAreaOrAnswerUbdate] = useState(null);

  let { id, companyName } = useParams();

  const handleChanchArea = (questionId) => {
    setFocusedAnswerId(questionId)
    /*if (showTextAreaOrAnswer) {
      setShowTextAreaOrAnswer(false);
    }
    setShowTextAreaOrAnswer((prev) => !prev);
    setShowTextAreaOrAnswerUbdate(index);*/
  };

  const filteredanswerd = useMemo(
    () => answeredQuestions?.filter((question) => question.answers[0]?.text).sort((a, b) => a.position - b.position),
    [answeredQuestions]
  );

  useEffect(() => {
    if(filteredanswerd.length !== 0) {
      setPositionOfNewAnswerQuestion(filteredanswerd[filteredanswerd.length - 1].position + 1);
    } else {
      setPositionOfNewAnswerQuestion(filteredanswerd.length + 1);
    }

  }, [filteredanswerd])

  const getColor = (state) => {
    switch (state) {
      case 1:
        return "#DF01D7";
      case 2:
        return "#FF0000";
      default:
        return "#0B6121";
    }
  };

  const getCurrentStateParameter = (priorityValue) => {
    if(priorityValue === "normal"){
      return 0
    } else if (priorityValue === "low"){
      return 1
    } else if (priorityValue === "hight"){
      return 2
    }
  }

  const handleSaveQuestionAnswerAfterUbdate = (data, text) => {
    const sendData = {
      text,
      question_id: data.id,
      state: getCurrentStateParameter(priorityValue),
      opportunity_id: companyName,
      platform: "web"
    };

    handleTextareaBlur();
    saveQuestionAnswer(sendData);
  };

  return (
    <>
      {filteredanswerd.length > 0 ? (
        <>
          <div className="cockpitUnswerTitileBlockAnaswered">
            <p className={isMicrosoftEdgeBrowser ? "cockpitUnswerTitile microsoftEdgeBrowser":"cockpitUnswerTitile"}>Answered</p>
            <div className={isMicrosoftEdgeBrowser ? "answeredCountWrapp microsoftEdgeBrowser":"answeredCountWrapp"}>
              <p className={isMicrosoftEdgeBrowser ? "asweredCount microsoftEdgeBrowser":"asweredCount"}>{filteredanswerd.length}</p>
            </div>
          </div>
          <div className="mockScrool"></div>

          <div className="AnsweredContainerScroolBar"
             style={{
               maxHeight: diagramsDataAfterUpdate.length > 1 ? '870px' : undefined
             }}>
            {filteredanswerd.map((answeredQuestion, index) => {
              return (
                <div
                  style={{
                    border: `${
                      focusedAnswerId === answeredQuestion.id
                        ? "1px solid #6DB6FE"
                        : "none"
                    }`,
                    boxShadow:
                    focusedAnswerId === answeredQuestion.id
                        ? " 0px 2px 10px rgba(15, 86, 224, 0.5)"
                        : "",
                  }}
                  key={index}
                  className="AnsweredContainer"
                >
                  <div className="kritikalPolos"
                       style={{
                         color: `${
                             focusedAnswerId === answeredQuestion.id
                                 ? "#0F56E0"
                                 : "#1c1c1c"
                         }`,
                       }}>
                  </div>
                  <div 
                    className= {`AnsweredContainerWithoutLeftLine ${(focusedAnswerId && focusedAnswerId === answeredQuestion.id) ? "AnsweredContainerWithoutLeftLineAcive" : ""}`}
                  >
                    <div index={index} className="spanDiv">
                      <span
                        className={isMicrosoftEdgeBrowser ? "AnsweredQuestionQuestionText microsoftEdgeBrowser":"AnsweredQuestionQuestionText"}>
                        {answeredQuestion.questionText}
                      </span>
                      {answeredQuestion.critical && (
                        <div className="blueCircleAnswerd"></div>
                      )}
                    </div>
                    {focusedAnswerId && focusedAnswerId === answeredQuestion.id ? (
                      <TextAreaAnswered
                        isMicrosoftEdgeBrowser={isMicrosoftEdgeBrowser}
                        answeredQuestion={answeredQuestion}
                        index={index}
                        handleSaveQuestionAnswerAfterUbdate={
                          handleSaveQuestionAnswerAfterUbdate
                        }
                        onClick={() => handleTextareaClick(answeredQuestion.id, objectiveId)}
                        type="text"
                        placeholder="Click to answer"
                        maxLength={500}
                        rows="3"
                        name={index}
                        data-name="name"
                        onBlur={handleTextareaBlur}
                        focusedAreaIndex={focusedAreaIndex}
                      />
                    ) : (
                      <div>
                        <div
                          className="answerDateName"
                          onClick={() => handleChanchArea(answeredQuestion.id)}>
                          <div className={isMicrosoftEdgeBrowser ?"AnsweredQuestionQuestionText microsoftEdgeBrowser AnsweredQuestionQuestionTextGrey" :"AnsweredQuestionQuestionText AnsweredQuestionQuestionTextGrey"}>
                            <span
                                style={{
                                  color: getColor(
                                      answeredQuestion.answers[
                                      answeredQuestion.answers.length - 1
                                          ]?.state
                                  ),
                                }}
                            >{answeredQuestion?.answers[0].text}</span>
                          </div>
                        </div>
                        <div className="userInfoWrapp">
                          <span className={isMicrosoftEdgeBrowser ? "nameLastName microsoftEdgeBrowser" : "nameLastName"}>
                            {answeredQuestion?.answers[0].name.toUpperCase()}
                          </span>
                          <span className={isMicrosoftEdgeBrowser ? "nameLastName microsoftEdgeBrowser" : "nameLastName"}>
                            {answeredQuestion?.answers[0].date.toUpperCase()}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <>
          <div className="cockpitUnswerTitileBlockAnaswered">
            <p className={isMicrosoftEdgeBrowser ? "cockpitUnswerTitile microsoftEdgeBrowser" : "cockpitUnswerTitile"}>Answered</p>
            <div className={isMicrosoftEdgeBrowser ? "answeredCountWrapp microsoftEdgeBrowser":"answeredCountWrapp"}>
              <p className="asweredCount">{filteredanswerd.length}</p>
            </div>
          </div>
          <div className="mockScrool"></div>
          {!!answeredQuestions.length && (
            <p className={isMicrosoftEdgeBrowser ? "notAnswer microsoftEdgeBrowser": "notAnswer"}>
              You don’t have answered questions yet.
              <br /> To answer a question сlick on the caption “Click to answer”
              to the right of the question.
            </p>
          )}
        </>
      )}
    </>
  );
};

export default inject(
  "questionsModel",
)(observer(Answered));
