import { makeAutoObservable } from "mobx";
import { authModel } from "./auth";

export class LoadingModel{
    constructor(){
        this.loadingModelState = false
        makeAutoObservable(this)
    }
    setLoadingModelState = newState =>{
        this.loadingModelState = newState
        if(newState){
            authModel.setNeedToRefresh(false)
            setTimeout(()=>{this.loadingModelState = false}, 2000)
        }
    }
}

export const loadingModel = new LoadingModel()