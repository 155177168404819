import React, { useState, useRef, useEffect } from "react";
import "./styles.scss";
import { inject, observer } from "mobx-react";
import ImgBuilder from "../../res/img/imgBuilder";
import UnAnswered from "./UnAnswered";
import Answered from "./Answered";
import ButtonsPanel from "./ButtonsPanel";

const CockpitBlock = (props) => {

  const {
    isMicrosoftEdgeBrowser,
    objectivesModel: {
      diagramsData,
      saveQuestionAnswerAfterUbdate,
      diagramsDataAfterUpdate,
      deleteDiagramInDiagramsDataAfterUpdate,
    },
    questionsModel:{
      lastPriority,
      focusedAnswerId,
      setLastPriority,
      setFocusedAnswerId
    }
  } = props;

  const [value, setValue] = useState("Normal");
  const [focusedAreaIndex, setFocusedAreaIndex] = useState(null);
  const [focusedObjective, setFocusedObjective] = useState(null)
  const btnRef = useRef();
  const [input, setInput] = useState(null);

  const handleChackRadio = (priority) => {
    setValue(priority);
    setLastPriority(priority);
    input.focus();
  };

  const handleTextareaClick = (id, objectiveId) => {
    (focusedAreaIndex !== id) && setValue("Normal");
    setFocusedAnswerId(id)
    setFocusedAreaIndex(id);
    setFocusedObjective(objectiveId)
  };
  const handleTextareaBlur = (ev) => {
    if(ev?.relatedTarget?.className !== "buttonsPanel" && !ev?.relatedTarget?.dataset?.property) {
      setFocusedAreaIndex(null);
      setLastPriority("")
      setFocusedAnswerId(null)
    } else {
      setInput(ev.target);
    }
  };

  const convertValue = (radioBtnValue) => {
    if (radioBtnValue === "High") {
      return `${radioBtnValue.toLowerCase()}t`;
    }
    return radioBtnValue.toLowerCase();
  };

  return (
    <div className="cockpitBlock">
      {diagramsData.length ? (
        diagramsDataAfterUpdate.length ? (
          diagramsDataAfterUpdate.map((diagramData, index) => {
            return (
              <div key={index} className="cockpitRoot">
                <div className="cockpitHeader">
                  <img
                    onClick={() =>
                      deleteDiagramInDiagramsDataAfterUpdate(diagramData.id)
                    }
                    className="cockpitCloseImge"
                    src={ImgBuilder.closeMessageIcon}
                    alt="svg"
                  />
                  <h1 className={isMicrosoftEdgeBrowser ? "cockpitTitle microsoftEdgeBrowser":"cockpitTitle"}>
                    {diagramData.title} ({diagramData.answeredQuestionCount}/
                    {diagramData.totalQuestionCount})
                  </h1>
                </div>
                <div className="cockpitWrapperAnswerdAnanswerd">
                  <div className="cockpitAnswerdAnanswerd">
                    <div className="cockpitAnswered">
                      <UnAnswered
                        isMicrosoftEdgeBrowser={isMicrosoftEdgeBrowser}
                        priorityValue={convertValue(value)}
                        objectiveId={diagramData.id}
                        unAnsweredQuestions={diagramData.questionInfo}
                        totalQuestionCount={diagramData.totalQuestionCount}
                        answeredQuestionCount={diagramData.answeredQuestionCount}
                        diagramsDataAfterUpdate={diagramsDataAfterUpdate}
                        handleTextareaClick={handleTextareaClick}
                        handleTextareaBlur={handleTextareaBlur}
                        focusedAreaIndex={focusedAreaIndex}
                      />
                    </div>
  
                    <div className="cockpitAnanswered">
                      <Answered
                        isMicrosoftEdgeBrowser={isMicrosoftEdgeBrowser}
                        priorityValue={convertValue(value)}
                        totalQuestionCount={diagramData.totalQuestionCount}
                        answeredQuestions={diagramData.questionInfo}
                        answeredQuestionCount={diagramData.answeredQuestionCount}
                        diagramsDataAfterUpdate={diagramsDataAfterUpdate}
                        handleTextareaClick={handleTextareaClick}
                        handleTextareaBlur={handleTextareaBlur}
                        focusedAreaIndex={focusedAreaIndex}
                        objectiveId={diagramData.id}
                        btnRef={btnRef}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                  {(focusedAnswerId && (focusedAnswerId !== null) && (focusedObjective === diagramData.id)) ? (
                      <ButtonsPanel
                        isMicrosoftEdgeBrowser={isMicrosoftEdgeBrowser}
                        handleChackRadio={handleChackRadio}
                        value={value}
                        btnRef={btnRef}
                      />
                    ): null}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="meetingCockpitWrapper">
            <p className={isMicrosoftEdgeBrowser ? "meetingCockpitSpan microsoftEdgeBrowser": "meetingCockpitSpan"}>
              To give an answer to the questions, select an objective
            </p>
            <img className="imgWrapperCockpit" src={ImgBuilder.selectPic} />
          </div>
        )
      ) : (
        <div className="meetingCockpitWrapper">
          <p className={isMicrosoftEdgeBrowser ? "meetingCockpitSpan microsoftEdgeBrowser": "meetingCockpitSpan"}>
            There are no objectives in this opportunity.
          </p>
        </div>
      )}
    </div>
  );
};

export default inject(
  "objectivesModel",
  "opportunitiesModel",
  "authModel",
  "questionsModel"
)(observer(CockpitBlock));
