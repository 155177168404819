import { makeAutoObservable } from "mobx";
import axios from "axios";
import { authModel } from "./auth";
import { objectivesModel } from "./objectives";
import { serverAPImodel } from "./serverAPI";

export class OpportunitiesModel {
  constructor() {
    this.opportunitiesList = [];
    this.currentOppotunity = null;
    this.forecastCategory = "All";
    this.opportunitiesListCommited = [];
    this.opportunitiesListExpected = [];
    this.opportunitiesListUpside = [];
    this.opportunitiesListPipeline = [];
    this.companiesList = [];
    this.casesList = [];
    this.productList = [];
    makeAutoObservable(this);
  }

  setProductList = (data) => {
    this.productList = data;
  };

  setCasesList = (data) => {
    this.casesList = data;
  };

  setCompaniesList = (data) => {
    this.companiesList = data;
  };

  setOpportunitiesList = (opportunitiesList) => {
    let filteredByStatus = opportunitiesList.filter(
      (opp) => opp.opportunity_status === "Open"
    );
    this.opportunitiesList = [...filteredByStatus]
      .reverse()
      .sort((a, b) => new Date(a.close_date) - new Date(b.close_date));
  };

  setForecastCategory = (forecastCategory) => {
    this.forecastCategory = forecastCategory;
  };

  checkIfUpdatedDisplayedFields = (opportunityId, action) => {
    const parametersObject = {
      user_id: authModel.currenManager.id,
      opportunity_status: "Open",
    };
    const opportunitiesList = this.opportunitiesList;
    const notIntrestedFields = [
      "action_item_id",
      "category_id",
      "category_id_list",
      "created_at",
      "id",
      "parthners_id_list",
      "updated_at",
      "synchronized_at",
      "ip_last_toggle",
      "excel_id",
      "sync_with_admin_state",
      "unread_messages_count",
      "removed_objectives",
      "objectives_id_list",
      "removed_objectives_id_list"
    ];
    const headers = {
      headers: {
        accept: "application/json, text/plain, */*",
        "auth-token": authModel.auth_token,
      },
      params: parametersObject,
    };

    axios
      .get(`${serverAPImodel.baseAPI}opportunities`, headers)
      .then(function (response) {
        let updatedOpportunityPreviousState = opportunitiesList.filter(
          (opp) => opp.id === opportunityId
        ).length
          ? opportunitiesList.filter((opp) => opp.id === opportunityId)[0]
          : null;
        let updatedOpportunityCurrentState = response.data.list.filter(
          (opp) => opp.id === opportunityId
        ).length
          ? response.data.list.filter((opp) => opp.id === opportunityId)[0]
          : null;
        if (action == "create" && updatedOpportunityCurrentState) {
          authModel.setNeedToRefresh(true);
          return;
        } else if (
          ["delete", "remove", "destroy"].includes(action) &&
          updatedOpportunityPreviousState
        ) {
          authModel.setNeedToRefresh(true);
          return;
        }
        if (
          !updatedOpportunityPreviousState &&
          !updatedOpportunityCurrentState
        ) {
          return false;
        }
        if (updatedOpportunityPreviousState) {
          console.log(
            "old",
            JSON.parse(JSON.stringify(updatedOpportunityPreviousState))
          );

          if (!updatedOpportunityCurrentState) {
            return authModel.setNeedToRefresh(true);
          }
          console.log(
            "new",
            JSON.parse(JSON.stringify(updatedOpportunityCurrentState))
          );

          let keys = Object.keys(updatedOpportunityCurrentState).filter(
            (key) => !notIntrestedFields.includes(key)
          );
          let result = keys.some(
            (key) =>
              updatedOpportunityPreviousState[key] !==
              updatedOpportunityCurrentState[key]
          );

          if (result) {
            authModel.setNeedToRefresh(true);
          }else{
            let objectivesOldKeysStr = updatedOpportunityPreviousState.objectives_id_list
            let objectivesNewKeysStr = updatedOpportunityCurrentState.objectives_id_list
            let objectivesOldKeys = objectivesOldKeysStr.split(";").filter(i => !updatedOpportunityPreviousState.removed_objectives_id_list.includes(i))
            let objectivesNewKeys = objectivesNewKeysStr.split(";").filter(i => !updatedOpportunityCurrentState.removed_objectives_id_list.includes(i))
            if(objectivesOldKeys.length === objectivesNewKeys.length){
              objectivesOldKeys.some((objId,index) => objId !== objectivesNewKeys[index]) &&
              authModel.setNeedToRefresh(true);
            }else{
              authModel.setNeedToRefresh(true);
            }
          }
        } else {
          authModel.setNeedToRefresh(true);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  setCurrentOppotunity = (opportunity) => {
    this.currentOppotunity = opportunity;
    objectivesModel.buildDiagramsData(opportunity);
    localStorage.setItem(
      "currentOppotunityIdCockpit",
      JSON.stringify(opportunity.company.id)
    );
  };

  setForecastFilteredLists = (data, cat) => {
    switch (cat) {
      case "Committed":
        this.opportunitiesListCommited = data;
        break;
      case "Expected":
        this.opportunitiesListExpected = data;
        break;
      case "Upside":
        this.opportunitiesListUpside = data;
        break;
      case "Pipeline":
        this.opportunitiesListPipeline = data;
        break;
    }
  };

  getOpportunitiesList = (forecastCat = null) => {
    const setOpportunitiesList = this.setOpportunitiesList;
    const setForecastFilteredLists = this.setForecastFilteredLists;
    const parametersObject = {
      user_id: authModel.currenManager.id,
      opportunity_status:"Open"
    };
    forecastCat && (parametersObject.category = forecastCat);
    const headers = {
      headers: {
        accept: "application/json, text/plain, */*",
        "auth-token": authModel.auth_token,
      },
      params: parametersObject,
    };

    axios
      .get(`${serverAPImodel.baseAPI}opportunities`, headers)
      .then(function (response) {
        let filteredOpportunitiesByQuestions =
          response.data.list.length &&
          objectivesModel.objectivesWithCriticalQuestions.length
            ? response.data.list.filter((opp) =>
                objectivesModel.objectivesWithCriticalQuestions.some((objId) => {
                     return opp.objectives_id_list.includes(objId)
                    }
                )
              )
            : [];
        forecastCat
          ? setForecastFilteredLists(
              filteredOpportunitiesByQuestions,
              forecastCat
            )
          : setOpportunitiesList(filteredOpportunitiesByQuestions);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  getModelList = (model) => {
    const setCompaniesList = this.setCompaniesList;
    const setProductList = this.setProductList;
    const setCasesList = this.setCasesList;
    const headers = {
      headers: {
        accept: "application/json, text/plain, */*",
        "auth-token": authModel.auth_token.length
          ? authModel.auth_token
          : localStorage.getItem("auth_token_cockpit"),
      },
    };
    axios
      .get(`${serverAPImodel.baseAPI}${model}`, headers)
      .then(function (response) {
        let resultList = response.data.list;
        switch (model) {
          case "companies":
            setCompaniesList(resultList);
            return;
          case "cases":
            setCasesList(resultList);
            return;
          case "products":
            setProductList(resultList);
            return;
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };
}

export const opportunitiesModel = new OpportunitiesModel();
