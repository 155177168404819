import React from "react";
import { inject, observer } from "mobx-react";
import "./styles.scss";

const OpportunityInfo = (props) => {
  const {
    isMicrosoftEdgeBrowser,
    opportunitiesModel: { currentOppotunity, companiesList, casesList },
  } = props;

  const shortMonthnames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let closeDateArray = currentOppotunity
    ? currentOppotunity.company.close_date.split("-")
    : null;

  const diagramTitlesData = currentOppotunity
    ? {
        companyName: companiesList.filter(
          (company) => company.id === currentOppotunity.company.company_id
        ).length
          ? companiesList.filter(
              (company) => company.id === currentOppotunity.company.company_id
            )[0].name
          : "",
        opportunityName: currentOppotunity.company.name,
        caseName: casesList.filter(
          (cas) =>
            currentOppotunity.company.cases_id_list.length &&
            currentOppotunity.company.cases_id_list.includes(cas.id)
        ).length
          ? casesList.filter(
              (cas) =>
                currentOppotunity.company.cases_id_list.length &&
                currentOppotunity.company.cases_id_list.includes(cas.id)
            )[0].name
          : "",
        closeDate: `${shortMonthnames[parseInt(closeDateArray[1]) - 1]} ${
          closeDateArray[2]
        }, ${closeDateArray[0]}`,
      }
    : {};

  const amountDisplay = (amount) => {
    if (amount > 999999) {
      let result = ((amount / 1000000).toFixed(1) + "").split(".");
      if (result[1] == 0) {
        return result[0] + "M";
      } else {
        return (amount / 1000000).toFixed(1) + "M";
      }
    } else if (amount > 999) {
      let result = ((amount / 1000).toFixed(1) + "").split(".");
      if (result[1] == 0) {
        return result[0] + "K";
      } else {
        return (amount / 1000).toFixed(1) + "K";
      }
    } else {
      return amount;
    }
  };

  return (
    <div>
        <div className="rowInfo">
            <div className={isMicrosoftEdgeBrowser ? "infoAboutCompany microsoftEdgeBrowser":"infoAboutCompany"}>{diagramTitlesData?.companyName}</div>
            {diagramTitlesData?.companyName && diagramTitlesData?.opportunityName
             && <div className={isMicrosoftEdgeBrowser ? "infoAboutCompany separator microsoftEdgeBrowser":"infoAboutCompany separator"}>|</div>}
            <div className={isMicrosoftEdgeBrowser ? "infoAboutCompany microsoftEdgeBrowser":"infoAboutCompany"}>{diagramTitlesData?.opportunityName}</div>
        </div>
        <div className="rowInfo">
            {diagramTitlesData?.caseName && <div
                className={isMicrosoftEdgeBrowser ? "infoAboutCase microsoftEdgeBrowser":"infoAboutCase"}>
                {diagramTitlesData?.caseName}
            </div>}
            <div
                className={isMicrosoftEdgeBrowser ? "infoAboutCase microsoftEdgeBrowser":"infoAboutCase"}>
                ${amountDisplay(currentOppotunity?.company?.amount)}
            </div>
            {diagramTitlesData?.closeDate && <div
                className={isMicrosoftEdgeBrowser ? "infoAboutCase microsoftEdgeBrowser":"infoAboutCase"}>
                {diagramTitlesData?.closeDate}
            </div>}
        </div>
    </div>
  );
};

export default inject(
  "opportunitiesModel"
)(observer(OpportunityInfo));
