import React, {useCallback, useEffect, useRef, useState} from "react";
import OpportunityInfo from "./OpportunityInfo";
import SingleOpportDiagram from "./SingleOpportDiagram";
import CardCarouselOpportunity from "./CardCarouselOpportunity";
import GoBackButton from "../../components/GoBackButton";
import { useNavigate } from "react-router";
import { homeUrl } from "../../res/consts/router_urls_const";
import { objectiveDetails } from "../../res/consts/router_urls_const";
import { inject, observer } from "mobx-react";
import "./styles.scss";
import ImgBuilder from "../../res/img/imgBuilder";
import Loader from "../Loader";

const OpportunityBlock = (props) => {
  const {
    data,
    currentCompany,
    isMicrosoftEdgeBrowser,
    objectivesModel: {
      objectivesList,
      setFilteredObjectivesList,
      setDiagramsData,
      diagramsData,
      getObjectivesList,
      buildDiagramsData,
    },
    opportunitiesModel: {
      currentOppotunity,
      opportunitiesList,
      setCurrentOppotunity,
      getOpportunitiesList,
    },
    authModel:{
      needToRefresh
    },
    loadingModel : {
      setLoadingModelState
    }
  } = props;

  useEffect(() => {
    if (!opportunitiesList.length) {
      (async () => {
        await getOpportunitiesList();
        await getObjectivesList();
      })();
    }
  }, [opportunitiesList]);

  useEffect(() => {
    if (opportunitiesList.length && window.location.pathname) {
      const idFromUrl = window.location.pathname.split("/")[2];
      const currentOpurtyunity = opportunitiesList.find(
        (op) => op.id === idFromUrl
      );
      setCurrentOppotunity({ company: currentOpurtyunity });
      objectivesList.length &&
        buildDiagramsData({ company: currentOpurtyunity });
    }
  }, [objectivesList, opportunitiesList, window.location.pathname]);

  const handleGoBack = () => {
    window.close()
  };

  const handleRefresh = () => {
    setLoadingModelState(true);
    const idFromUrl = window.location.pathname.split("/")[2];
    const currentOpportunity = opportunitiesList.find(
        (op) => op.id === idFromUrl
    );

    (async () => {
      await getOpportunitiesList();
      await getObjectivesList();
      setCurrentOppotunity({ company: currentOpportunity });
      buildDiagramsData({ company: currentOpportunity });
    })();
  };

  return (
    <div className="containerOpportDiagrams">
      <div className="backToOverviewContainer">
        <div>
          <GoBackButton text="← Back to Overview" goBackFunction={handleGoBack} isMicrosoftEdgeBrowser={isMicrosoftEdgeBrowser}/>
          <OpportunityInfo isMicrosoftEdgeBrowser={isMicrosoftEdgeBrowser}/>
        </div>
        <div className={isMicrosoftEdgeBrowser ? "microsoftEdgeBrowser refreshButton" : "refreshButton"}
             onClick={() => handleRefresh()}>
          <img src={ImgBuilder.brightReloadIcon} />
          <span>Refresh</span>
        </div>
      </div>
      <div className="containerOpportDiagramsContainer">
        {diagramsData.length ? (
          <div className="opportunityCarousel">
            <CardCarouselOpportunity
              alignment={"end"}
              data={[].concat(diagramsData).map((objective, index) => (
                <SingleOpportDiagram
                  isMicrosoftEdgeBrowser={isMicrosoftEdgeBrowser}
                  currentIndex={index}
                  objectiveId={objective.id}
                  diagramTitle={objective.title}
                  totalQuestionCount={objective.totalQuestionCount}
                  answeredQuestionCount={objective.answeredQuestionCount}
                  detailsUrl={objectiveDetails}
                  type="mainPage"
                  index={index + 1}
                  currentCompany={currentOppotunity.company.id}
                  key={index}
                />
              ))}
            />
          </div>
        ) : (
          <>
            <div className="opportunityCarousel"></div>
          </>
        )}
      </div>
    </div>
  );
};

export default inject(
  "objectivesModel",
  "opportunitiesModel",
  "loadingModel",
  "authModel"
)(observer(OpportunityBlock));
