import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'mobx-react';
import HttpsRedirect from 'react-https-redirect';
import { authModel } from './models/auth';
import { opportunitiesModel } from './models/opportunities';
import { objectivesModel } from './models/objectives';
import { loadingModel } from './models/loading';
import { serverAPImodel } from './models/serverAPI';
import { questionsModel } from './models/questions'

import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, } from 'react-router-dom';

const models = {
  authModel: authModel,
  opportunitiesModel: opportunitiesModel,
  objectivesModel: objectivesModel,
  loadingModel: loadingModel,
  serverAPImodel: serverAPImodel,
  questionsModel: questionsModel
}

ReactDOM.render(
  
    <Provider {...models}>
      <HttpsRedirect>
    <Router>  
        <App />
    </Router>
      </HttpsRedirect>
    </Provider>,
  document.getElementById('root')
);

reportWebVitals();
