import React, { useState, useEffect } from "react"
import useUpdateSocket from "../../sockets/userChannel";
import useQuestionsSocket from "../../sockets/questionChannel";
import { observer, inject } from "mobx-react";
import ImgBuilder from "../../res/img/imgBuilder";

import "./styles.scss"

const NeedToRefreshAlert = (props) => {
    const {
        tablewidth,
        page = "normal",
        serverAPImodel:{baseAPI},
        authModel : {
            needToRefresh,
            setNeedToRefresh,
            currenManager
        },
        objectivesModel:{
            checkIfUpdatedQuestionChanged,
            checkIfOpenOpportunityDataChanged,
            objectivesList,
            getObjectivesList,
            buildDiagramsData,
        },
        opportunitiesModel: {
            opportunitiesList,
            setCurrentOppotunity,
            getOpportunitiesList,
            checkIfUpdatedDisplayedFields,
        },
    } = props

    const [tabVisibility, updateTabVisibility] = useState(true)

    let wsLink = ""
    switch(baseAPI){
        case ("https://spotlogic-legacy.herokuapp.com/api/v1/"):
            wsLink = "wss://spotlogic-legacy.herokuapp.com/cable/"
            break
        case ("https://spotlogicqaserver.pp.ua/api/v1/"):
            wsLink = "wss://spotlogicqaserver.pp.ua/cable/"
            break
        case ("https://xerox.pp.ua/api/v1/"):
            wsLink = "wss://xerox.pp.ua/cable/"
            break
        case ("https://spotlogic-mlogica.pp.ua/api/v1/"):
            wsLink = "wss://spotlogic-mlogica.pp.ua/cable"
        case ("https://netappspotlogic.pp.ua/api/v1/"):
            wsLink = "wss://netappspotlogic.pp.ua/cable"
        case ("https://spotlogic-matterport.pp.ua/api/v1/"):
            wsLink = "wss://spotlogic-matterport.pp.ua/cable"
        case ("https://spotlogic-solrenewables.pp.ua/api/v1/"):
            wsLink = "wss://spotlogic-solrenewables.pp.ua/cable"
        case ("https://spotlogic-scholarpath.pp.ua/api/v1/"):
            wsLink = "wss://spotlogic-scholarpath.pp.ua/cable"
    }

    useEffect(() => {
        !tabVisibility && needToRefresh && document.location.reload()
    }, [needToRefresh])

    const handleRefresh = () => {
        const idFromUrl = window.location.pathname.split("/")[2];

        if(!!idFromUrl){
            const currentOpportunity = opportunitiesList.find(
                (op) => op.id === idFromUrl
            );

                (async () => {
                    await getOpportunitiesList();
                    await getObjectivesList();
                    setTimeout(() => {
                        setCurrentOppotunity({ company: currentOpportunity });
                        buildDiagramsData({ company: currentOpportunity });
                    }, 2000);

                    setNeedToRefresh(false);
                })();

            return;
        }

        (async () => {
            await getObjectivesList();
            setNeedToRefresh(false)
        })();
    };

    useEffect(() => {
        if(needToRefresh) {
            handleRefresh();
        }
    }, [needToRefresh]);

    document.addEventListener('visibilitychange', function (event) {
        if (document.hidden) {
          tabVisibility && updateTabVisibility(false)
        } else {
          !tabVisibility && updateTabVisibility(true)
        }
    });

    const updatesSocket = useUpdateSocket(wsLink);

    const parseUpdatesSocketMessage = message => {
        const data = JSON.parse(message.data);
        if(["ping","welcome","confirm_subscription"].includes(data.type)){
            return;
        }else if(["ActionItem", "Item", "Category", "Company", "Case", "Product","Topic"].includes(data?.message?.model)){
            return
        }else if(data?.message?.model === "Opportunity"){
            checkIfUpdatedDisplayedFields(data?.message?.id, data?.message?.event)
        }else if(["Objective"].includes(data?.message?.model)){
            let objectivesListIds = objectivesList.map(objective => objective.id)
            if(["update", "destroy", "delete"].includes(data?.message?.event) && !objectivesListIds.includes(data?.message?.id)){
                return
            }
            checkIfUpdatedQuestionChanged(data?.message?.id)
        }else if(["Question", "Answer"].includes(data?.message?.model)){
            checkIfOpenOpportunityDataChanged(data?.message?.model, data?.message?.id)
        }else{
            setNeedToRefresh(true)
        }
    }

    useEffect(() => {
        if(updatesSocket){
            updatesSocket.onmessage = message => {
                !needToRefresh && parseUpdatesSocketMessage(message)
            }
        }

    },[updatesSocket])

    const questionSocket = useQuestionsSocket(wsLink);

    useEffect(() => {
        if(questionSocket){
            questionSocket.onmessage = message => {
                parseUpdatesSocketMessage(message)
            }
        }
    },[questionSocket])

    useEffect(() => {
        return  (() => {updatesSocket && updatesSocket.close(); setNeedToRefresh(false)})()
    }, [])

    useEffect(() => () => updatesSocket && updatesSocket.close(), [updatesSocket])
    useEffect(() => () => questionSocket && questionSocket.close(), [questionSocket])
    return null ?
        <>
            <div className="needToRefreshModal" style ={{width:tablewidth}}>
                <img src = {ImgBuilder.whiteTriangularIcon}/>
                <span>Selected user made some changes. Please, refresh the page to see the updates. </span>
            </div>
            <div className="needToRefreshOverlay" style ={{width:tablewidth, top: page === "objectiveDetails" && "140px"}}/>
        </>: null
}

export default inject(
    "opportunitiesModel",
    "objectivesModel",
    "authModel",
    "serverAPImodel",
    "loadingModel",
)(observer(NeedToRefreshAlert))
