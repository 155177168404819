import React, { useEffect, useState } from "react";
import TotalDiagram from "./TotalDiagram";
import SingleObjectiveDiagram from "./SingleObjectiveDiagram";
import CardCarousel from "../CardCarousel";
import { objectiveDetails } from "../../res/consts/router_urls_const";
import { inject, observer } from "mobx-react";

import "./styles.scss";

const ObjectiveDiagrams = (props) => {
  const {
    data,
    currentCompany,
    objectivesModel: {
      objectivesList,
      setFilteredObjectivesList,
      setDiagramsData,
      diagramsData,
      buildDiagramsData,
    },
    opportunitiesModel: {
      currentOppotunity,
      opportunitiesList,
      setCurrentOppotunity,
    },
  } = props;

  const [diagramsDataState, setDiagramsDataState] = useState(diagramsData);

  useEffect(() => {
    setDiagramsDataState(diagramsData);
  }, [diagramsData]);

  useEffect(() => {
    objectivesList.length &&
      buildDiagramsData({ company: opportunitiesList[0] });
  }, [objectivesList, opportunitiesList]);

  let cardsPerSlide = 5;
  /*let screenWidth = window.innerWidth;
  if (screenWidth >= 768 && screenWidth < 1200) {
    cardsPerSlide = 2;
  } else if (screenWidth < 768) {
    cardsPerSlide = 1;
  } else if(screenWidth > 1400){
    cardsPerSlide = Math.round(screenWidth*0.8 - 400)/150 
  }*/
  return (
    <div className="mt-2 p-4 containerObjectiveDiagrams">
      <TotalDiagram data={diagramsDataState.length ? diagramsDataState : []} />
      {diagramsDataState.length ? (
        <CardCarousel
          cardsPerSlide={cardsPerSlide}
          cardWidth={150}
          alignment={"end"}
          data={diagramsDataState.map((objective, index) => (
            <SingleObjectiveDiagram
              diagramTitle={objective.title}
              totalQuestionCount={objective.totalQuestionCount}
              answeredQuestionCount={objective.answeredQuestionCount}
              detailsUrl={objectiveDetails}
              type="mainPage"
              index={index + 1}
              currentCompany={currentOppotunity.company.id}
              key={objective.id}
            />
          ))}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default inject(
  "objectivesModel",
  "opportunitiesModel"
)(observer(ObjectiveDiagrams));
