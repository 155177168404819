import React, { useEffect, useState } from 'react';
import OpportunityBlock from '../../components/OpportunityBlock';
import CockpitBlock from '../../components/CockpitBlock';
import NeedToRefreshAlert from '../../components/NeedToRefreshAlert';
import { inject, observer } from "mobx-react"
import { useParams } from "react-router";

const OpportunityPage = (props) => {
    const {
        authModel : {
            tableSizeParams,
        },
        opportunitiesModel :{companiesList, opportunitiesList}
    } = props

    let { id, companyName } = useParams();
    let modifyLocalStorage = (action) =>{

        //opp id
        let openedOpportunitiesArr = localStorage.getItem("openedOpportunityCockpit") ?
            localStorage.getItem("openedOpportunityCockpit").split(",").filter(opp => companyName !== opp) :
            []
        let openedOpportunitiesStr

        if(action === "close"){
            openedOpportunitiesStr = openedOpportunitiesArr.join(",")
        }else{
            openedOpportunitiesArr.push(companyName);
            openedOpportunitiesStr = openedOpportunitiesArr.join(",")
        }
        localStorage.setItem("openedOpportunityCockpit", JSON.parse(JSON.stringify(openedOpportunitiesStr)))

        //opp company name
        let openedOpportunity = opportunitiesList.find(opp => companyName === opp.id)
        let companeTitle = companiesList.find(comp => comp.id === openedOpportunity.company_id)?.name

        if(companeTitle){
            document.title = companeTitle
        }
    }

    const closingCode =  () => {
        modifyLocalStorage("close")
        return null;
    }

    useEffect(() => {
        modifyLocalStorage("open")
    },[opportunitiesList])

    window.onbeforeunload = closingCode;

    const [isMicrosoftEdgeBrowser, setIsMicrosoftEdgeBrowser] = useState(/Edg/.test(navigator.userAgent) && window.innerWidth < 748);
    const resizeHandler = () => {
        if(/Edg/.test(navigator.userAgent) && window.innerWidth < 748) {
            setIsMicrosoftEdgeBrowser(true);
        } else {
            setIsMicrosoftEdgeBrowser(false);
        }
    }

    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        resizeHandler();
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, []);

    return (
        <div>
            <OpportunityBlock isMicrosoftEdgeBrowser={isMicrosoftEdgeBrowser}/>
            <CockpitBlock isMicrosoftEdgeBrowser={isMicrosoftEdgeBrowser}/>
            <NeedToRefreshAlert tablewidth={`${tableSizeParams.width > 100 ? tableSizeParams.width : 100}%`}/>
        </div>
    );
};

export default inject(
    "authModel",
    "loadingModel",
    "opportunitiesModel"
)(observer(OpportunityPage));
