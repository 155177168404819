import React from "react"
import ImgBuilder from "../../res/img/imgBuilder"

import "./styles.scss"

const MailLink = () => {
    return <div className = "pageContainerMailLink">
        <div className = "containerMailLink">
            <div className = "headerMailLink">
                <img src = {ImgBuilder.mainlogo}/>
            </div>
            <div className = "contentMailLink">
                <div className = "titleMailLink">Hello, Alex!</div>
                <div className = "subtitleMailLink">
                    You received this email because you used it. 
                    Password recovery function. 
                    To change your password, click the link below.
                </div>
                <a href = "http://www.spotlogic.com/resetpassword/userid?1548">
                    http://www.spotlogic.com/resetpassword/userid?1548
                </a>
                <div className = "subtitleMailLink">
                    If you have not requested to change your password
                </div>
                <div className = "regardsMailLink">
                    <div className = "lightRegardsMailLink">Best regards,</div>
                    <div className = "boldRegardsMailLink">Spotlogic Team</div>
                </div>
            </div>
        </div>
    </div>
}

export default MailLink