import React from "react"
import ImgBuilder from "../../res/img/imgBuilder"
import "./styles.scss"

const EmptyPage = () => {
    return <div className = "containerEmptyPage">
        
        <img src = {ImgBuilder.emptyPageImage}/>
        <div className = "titleEmptyPage">No data to display</div>
        <div className = "subtitleEmptyPage">First, select the username you want to view information about from the drop-down list at the top.</div>
   
    </div>
}

export default EmptyPage