import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { inject, observer } from "mobx-react";

import "./styles.scss";
import { toJS } from "mobx";

const SingleObjectiveDiagram = (props) => {
  const {
    totalQuestionCount,
    answeredQuestionCount,
    diagramTitle,
    detailsUrl = false,
    type = "simple",
    index = false,
    currentCompany = false,
    objectivesModel: {
      objectivesList,
      setCurrentObjective,
      filteredObjectivesList,
      diagramsData,
    },
  } = props;

  const [answersDataState, setAnswersDataState] = useState([]);
  useEffect(() => {
    if (index && diagramsData[index - 1]?.questionInfo) {
      let answersDataStateDraft = diagramsData[index - 1].questionInfo.filter(
        (question) => question.answers[0]?.text
      );
      let greenQuestions = answersDataStateDraft.filter(
        (question) => question.answers[0].state === 0
      );
      let pinkQuestions = answersDataStateDraft.filter(
        (question) => question.answers[0].state === 1
      );
      let redQuestions = answersDataStateDraft.filter(
        (question) => question.answers[0].state === 2
      );
      let result = []
        .concat(greenQuestions)
        .concat(pinkQuestions)
        .concat(redQuestions);
      setAnswersDataState(result);
    } else {
      setAnswersDataState([]);
    }
  }, [diagramsData]);

  const devidersAngles = [];
  for (let i = 0; i < totalQuestionCount; i++) {
    i
      ? devidersAngles.push((360 / totalQuestionCount) * i)
      : devidersAngles.push(0);
  }
  const diagramAngle = 360 / totalQuestionCount;

  const navigate = useNavigate();

  const handleClick = () => {
    if (type === "mainPage" && index && detailsUrl && currentCompany) {
      setCurrentObjective(filteredObjectivesList[index - 1]);
      return navigate(`${detailsUrl}/${currentCompany}/${index}`);
    }
  };

  return (
    <div
      className="containerSingleObjectiveDiagram col-2"
      onClick={() => handleClick()}
    >
      <div className="circleWrapSingleObjectiveDiagram">
        <div className="circleSingleObjectiveDiagram">
          {diagramAngle > 0 &&
            answersDataState.map((answerItem, answerIndex) => (
              <div key={answerIndex}>
                <div
                  className="maskMint fullMint"
                  style={{
                    transform: `rotate(${diagramAngle * answerIndex}deg)`,
                    clip: `rect(0px, 120px, 120px, ${
                      totalQuestionCount === 1 ? "0" : "59"
                    }px)`,
                  }}
                >
                  {totalQuestionCount > 1 ? (
                    <div
                      className={`fillMint AnswerColor${answerItem.answers[0].state}`}
                      style={{
                        transform: `rotate(${diagramAngle}deg)`,
                      }}
                    ></div>
                  ) : (
                    <div
                      className={`circle AnswerColor${answerItem.answers[0].state}`}
                    ></div>
                  )}
                </div>
              </div>
            ))}

          <div className="maskGrey fullGrey">
            <div className="fillGrey"></div>
          </div>
          <div className="maskGrey halfGrey">
            <div className="fillGrey"></div>
          </div>
          {devidersAngles.length > 1 &&
            devidersAngles.map((angle, angIndex) => (
              <div
                className="circleDevider"
                style={{ transform: `rotate(${angle}deg)` }}
                key={angIndex}
              ></div>
            ))}
          <div className="insideCircle">
            {answeredQuestionCount}/{totalQuestionCount}
          </div>
        </div>
      </div>
      <div className="titleSingleObjectiveDiagram">{diagramTitle}</div>
    </div>
  );
};

export default inject(
  "objectivesModel",
  "opportunitiesModel"
)(observer(SingleObjectiveDiagram));
