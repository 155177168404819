import { makeAutoObservable } from "mobx";
import { authModel } from "./auth";
import axios from "axios";
import { serverAPImodel } from "./serverAPI";
import { objectivesModel } from "./objectives";

export class QuestionsModel{
    constructor(){
        this.focusedAnswerId = null
        this.lastPriority = ""
        this.positionOfNewAnswerQuestion = 0;
        makeAutoObservable(this)
    }

    setPositionOfNewAnswerQuestion = position => {
        this.positionOfNewAnswerQuestion = position;
    }

    setLastPriority = priority =>{
        this.lastPriority = priority //string
    }

    setFocusedAnswerId = id =>{
        this.focusedAnswerId= id //string
    }

    removeFocusedAnsweredId = () => {
        this.focusedAnswerId = null;
    }

    changeQuestionPosition = async (position, id, headers) => {
        await axios.put(`${serverAPImodel.baseAPI}questions/${id}`, {position}, headers);
    }
    
    saveQuestionAnswer = async (payload, position) => {
        try {
          const headers = {
            headers: {
              accept: "application/json, text/plain, */*",
              "auth-token": authModel.auth_token,
            },
          };
          let oppId = localStorage.getItem("currentOppotunityIdCockpit").includes('"')
          ? localStorage
              .getItem("currentOppotunityIdCockpit")
              .substring(1)
              .slice(0, -1)
          : localStorage.getItem("currentOppotunityIdCockpit")
          payload.opportunity_id = oppId
          this.removeFocusedAnsweredId();
          await axios.post(`${serverAPImodel.baseAPI}answers`, payload, headers);
          if(position) {
             await this.changeQuestionPosition(position, payload.question_id, headers);
          }
          await objectivesModel.getObjectivesList();
        } catch (e) {
          console.error(e);
        }
    };
}

export const questionsModel = new QuestionsModel()